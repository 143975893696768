import { useContext, useState } from "react";
import { AppOrganizationViewContext } from "../../../contexts/AppOrganizationViewProvider";
import { UserContext } from "../../../contexts/UserProvider";
import { Dropdown } from 'antd';
import { AppViewDropdownButton, AppViewDropdownUserAvatar } from "./MenuSideBarOrgDropdown.styles"
import Organization from "../../../types/Organization";
import { navigate } from "@reach/router";
import pages from "../../../constants/pages";
import notifyError from "../../../util/notifyError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

const MenuSidebarOrgDropdown: React.FC = () => {
  const { user } = useContext(UserContext);
  const {
    organizations,
    organizationId,
    selectedOrganization,
    switchToPersonal,
    switchToOrganization
  } = useContext(AppOrganizationViewContext);
  const userAvatar = <AppViewDropdownUserAvatar src={user?.picture} />
  const orgAvatar = (org: Organization) => <AppViewDropdownUserAvatar>{org.name.slice(0, 1).toUpperCase()}</AppViewDropdownUserAvatar>
  const activeAvatar = organizationId === null
    ? null
    : !selectedOrganization 
      ? userAvatar
      : orgAvatar(selectedOrganization)
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const onClickSwitchToPersonal = () => {
    setDropdownVisible(false)
    switchToPersonal();
    navigate(pages.APP);
  }

  const onClickSwitchToOrganization = async (orgId: string) => {
    try {
      setDropdownVisible(false);
      await switchToOrganization(orgId);
    } catch (err) {
      notifyError(`Failed to switch to organization`, (err as Error).message)
    }
    navigate(pages.APP);
  }


  return (
    <div style={{
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
    }}>
      <Dropdown 
        trigger={['click']} 
        open={dropdownVisible}
        onOpenChange={setDropdownVisible}
        {...organizations?.length && ({
          menu: {
            items: [
              {
                key: 'personal',
                label: user?.name,
                icon: userAvatar,
                onClick: onClickSwitchToPersonal,
              },
              ...organizations.map((org) => ({
                key: org.id,
                label: org.name,
                onClick: () => onClickSwitchToOrganization(org.id),
                icon: orgAvatar(org)
              }))
            ]
          }
        })}
      >
        <div style={{
          width: '100%',
        }}>
          <AppViewDropdownButton type="text" disabled={organizationId === null}>
            <div style={{
              display: 'inline',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'white',
            }}>
              {activeAvatar}
              <span>
                {organizationId === null ? 'Loading...' : !selectedOrganization ? user?.name : selectedOrganization.name}
              </span>
            </div>
            <FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} style={{color: 'white'}} />
          </AppViewDropdownButton>
        </div>
      </Dropdown>
    </div>
  )
}

export default MenuSidebarOrgDropdown;
