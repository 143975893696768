import { useQuery } from "react-query"
import useAuthZero from '../../../hooks/useAuthZero';
import { getOrganizationGoal, GetOrganizationGoalInput } from "../api";

interface InputEnabled {
  taskTemplateId: string
  input: GetOrganizationGoalInput
}

interface InputDisabled {
  taskTemplateId: undefined | null
}

export const useOrganizationGoal = (input: InputEnabled | InputDisabled) => {
  const { getAccessToken } = useAuthZero()

  const {
    data,
    status,
    error,
  } = useQuery(['organization-goal', input], async () => {
    if (!input.taskTemplateId) {
      throw new Error('taskTemplateId is required to get organization goal')
    }

    const accessToken = await getAccessToken()()

    return getOrganizationGoal({
      ...input.input
    }, accessToken)
  }, {
    enabled: !!input.taskTemplateId,
  })

  return {
    data,
    status,
    error,
  }
}
