import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import themeColors from '../../../constants/themeColors';
import { JobAppContext } from '../../../contexts/JobAppProvider';
import JobApp from '../../../types/JobApp';
import notifyError from '../../../util/notifyError';
import AutoCompleteCompanyInput from '../AutoCompleteCompanyInput';

const EditCompanyContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ClickCompanyToEditContainer = styled.div`
  h1 {
    margin: 0;
  }
`;

interface JobAppEditCompanyProps {
  jobApp: JobApp;
  onSelected: (selected: { value: string, logoUrl?: string }|null) => void;
}

const JobAppEditCompany: React.FC<JobAppEditCompanyProps> = ({
  jobApp,
  onSelected,
}) => {
  const [editingCompany, setEditingCompany] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editingCompanySelected, setEditingCompanySelected] = useState<{
    value: string,
    logoUrl?: string
  }|null>(null);
  const { updateJobApp } = useContext(JobAppContext);

  useEffect(() => {
    setEditingCompanySelected(null);
  }, [editingCompany]);

  useEffect(() => {
    onSelected(editingCompanySelected);
  }, [editingCompanySelected, onSelected]);

  const saveNewCompany = async () => {
    if (!editingCompanySelected || !jobApp) {
      return;
    }
    setSaving(true);
    try {
      const toUpdate = {
        company: editingCompanySelected.value,
        companyLogoUrl: editingCompanySelected.logoUrl,
      };
      await updateJobApp(jobApp.id, toUpdate);
      setEditingCompany(false);
    } catch (err) {
      notifyError('Unable to edit job app', (err as Error).message);
    } finally {
      setSaving(false)
    }
  };

  if (editingCompany) {
    return (
      <EditCompanyContainer>
        <AutoCompleteCompanyInput
          onChange={setEditingCompanySelected}
          style={{
            marginRight: '0.5rem'
          }}
        />
        <Button
          type="text"
          htmlType="submit"
          disabled={saving}
          icon={(
            <FontAwesomeIcon
              icon={faCheck}
              size="lg"
              style={{ color: themeColors.semantic.success }}
            />
          )}
          onClick={saveNewCompany}
        />
        <Button
          type="text"
          htmlType="button"
          disabled={saving}
          icon={(
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
            />
          )}
          onClick={() => setEditingCompany(false)}
        />
      </EditCompanyContainer>
    );
  }

  return (
    <ClickCompanyToEditContainer
      onKeyPress={(e) => (e.key === 'Enter' ? setEditingCompany(true) : null)}
      onClick={() => setEditingCompany(true)}
      role="button"
      tabIndex={0}
    >
      <h1>
        {jobApp.company}
      </h1>
    </ClickCompanyToEditContainer>
  );
};

export default JobAppEditCompany;
