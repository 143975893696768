import { useQuery } from "react-query"
import useAuthZero from '../../../hooks/useAuthZero';
import { getInterviews } from "../api";

interface Options {
  limit: number
  offset: number
  filters: {
    gte: Date,
    lte: Date,
  };
}

export const useInterviews = ({
  filters,
  limit,
  offset,
}: Options) => {
  const { getAccessToken } = useAuthZero()

  const {
    data,
    status,
    error,
  } = useQuery(['interviews', {
    gte: filters.gte.toISOString(),
    lte: filters.lte.toISOString(),
    limit,
    offset
  }], async () => {
    const accessToken = await getAccessToken()()

    return getInterviews({ 
      filters: {
        gte: filters.gte,
        lte: filters.lte,
      },
      limit,
      offset
    }, accessToken)
  }, {
    keepPreviousData: true,
  })

  return {
    data,
    status,
    error,
  }
}
