import { Button, Space } from "antd"
import { useContext, useRef, useState } from "react"
import { InterviewQuestionContext } from "../../../../../contexts/InterviewQuestionProvider"
import JobAppInterviewQuestion from "../../../../../types/JobAppInterviewQuestion"
import InterviewQuestionAnswerTextArea from "./InterviewQuestionAnswerTextArea/InterviewQuestionAnswerTextArea"
import InterviewQuestionQuestionInput from "./InterviewQuestionQuestionInput/InterviewQuestionQuestionInput"
import notifyError from "../../../../../util/notifyError"
import themeColors from "../../../../../constants/themeColors"

interface FormState {
  question: string;
  answer: string;
}

interface Props {
  question: JobAppInterviewQuestion
  onDeleted: (question: JobAppInterviewQuestion) => void
  onUpdated: (question: JobAppInterviewQuestion) => void
}

const InterviewQuestion: React.FC<Props> = ({
  question,
  onDeleted,
  onUpdated,
}) => {
  const {
    updateInterviewQuestion,
    deleteInterviewQuestion,
  } = useContext(InterviewQuestionContext)
  const [isFocused, setIsFocused] = useState(false)
  const [formState, setFormState] = useState<FormState>({
    question: question.question,
    answer: question.answer
  })
  const containerRef = useRef<HTMLDivElement>(null);

  const onCancel = () => {
    setIsFocused(false)
    setFormState({
      question: question.question,
      answer: question.answer
    })
  }

  const onSave = async () => {
    const noQuestionChange = !formState.question || formState.question === question.question
    const noAnswerChange = formState.answer === question.answer
    if (noQuestionChange && noAnswerChange) {
      setIsFocused(false)
      return
    }
    const trimmedInputs: FormState = {
      question: formState.question.trim(),
      answer: formState.answer.trim()
    }
    setFormState(trimmedInputs)
    onUpdated({
      ...question,
      ...trimmedInputs
    })
    setIsFocused(false)
    try {
      await updateInterviewQuestion(question.id, {
        question: trimmedInputs.question,
        answer: trimmedInputs.answer,
      });
    } catch (err) {
      notifyError(`Failed to update interview question`, (err as Error).message)
      onCancel()
    }
  }

  const onDelete = async () => {
    try {
      await deleteInterviewQuestion(question.id)
      setIsFocused(false)
      onDeleted(question);
    } catch (err) {
      notifyError(`Failed to delete interview question`, (err as Error).message)
    }
  }

  const onBlur = () => {
    onSave()
  }

  const onFocus = () => {
    setIsFocused(true);
  }

  const onQuestionChange = (question: string) => {
    setFormState({
      ...formState,
      question
    })
  }

  const onAnswerChange = (answer: string) => {
    setFormState({
      ...formState,
      answer
    })
  }

  return <div
    ref={containerRef}
    onFocus={onFocus}
    style={{
      display: 'grid',
      rowGap: '0.5rem',
      marginBottom: '1rem',
      ...(isFocused && ({
        // background: themeColors.background.offWhite,
        // padding: '1rem 1rem 1rem 1rem',
        borderRadius: '5px',
        border: `solid 1px ${themeColors.background.white}`
      }))
    }}>
    {/* <span>{formState.question}</span> */}
    {!isFocused && <span style={{
      fontWeight: 500
    }}>{formState.question}</span>}
    {isFocused && <InterviewQuestionQuestionInput
      text={formState.question} 
      onChange={onQuestionChange}
      onEnter={onBlur}
      focused={isFocused}
    />}
    {!isFocused && <span>{formState.answer}</span>}
    {isFocused && <InterviewQuestionAnswerTextArea
      text={formState.answer}
      onChange={onAnswerChange}
    />}
    {!isFocused && <div><Button size="small">Edit</Button></div>}
    {isFocused && <div
      onMouseDown={e => e.preventDefault()}
      style={{display: 'flex', justifyContent: 'space-between'}}
    >
      <Space onMouseDown={e => e.preventDefault()}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={onSave}>Save</Button>
      </Space>
      <Button danger onClick={onDelete}>Delete</Button>
    </div>
    }
  </div>
}

export default InterviewQuestion