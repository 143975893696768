import styled from "styled-components";
import themeColors from "../../../../../constants/themeColors";

export const ContactCardCard = styled.div`
  position: relative;
  /** Use 3rem on right side to allow space for edit button */
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  border: solid 1px ${themeColors.background.offWhite};
  word-break: break-word;
  display: grid;
  row-gap: 10px;
  background: white;
  border-radius: 10px;
  .header-container {
    margin-right: 4rem;
  }
  &:hover, &:focus, &:focus-within {
    > div:first-child {
      opacity: 1;
    }
  }
  > div:first-child {
    opacity: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  button {
    color: ${themeColors.background.gray};
  }
`

export const ContactCardHeader = styled.div`
  display: grid;
  h2 {
    margin: 0;
    font-size: 1rem;
    color: ${themeColors.background.dark};
    font-weight: 600;
  }
  span {
    margin-top: -3px;
  }
`

export const ContactCardContactContainer = styled.div`
  display: grid;
`

export const ContactCardNotes = styled.p`
  margin: 0;
`
