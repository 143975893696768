import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import themeColors from "../../../../constants/themeColors";
import JobApp from "../../../../types/JobApp";
import JobAppNote from "../../../../types/JobAppNote";
import AddNotePopover from "./AddNotePopover";
import Note from "./Note/Note";
import { NotesListAddButtonContainer, NotesListContainer } from "./NotesList.styles";

const StyledCollapse = styled(Collapse)`
  .anticon {
    color: ${themeColors.brand.primaryLight} !important;
  }
  .ant-collapse-item > .ant-collapse-header {
    color: ${themeColors.background.dark};
  }
  .interview-edit-buttons {
      opacity: 0;
  }
  .ant-collapse-item:hover {
    .interview-edit-buttons {
      opacity: 1;
    }
  }
`;

interface Props {
  jobApp: JobApp;
  onAddedNote: (note: JobAppNote) => void;
  onUpdatedNote: (note: JobAppNote) => void;
  onRemovedNote: (note: JobAppNote) => void;
}

const NotesList: React.FC<Props> = ({
  jobApp,
  onAddedNote,
  onUpdatedNote,
  onRemovedNote,
}) => {

  const notes = jobApp.notesList
    .sort((a, b) => dayjs(a.createdAt).isBefore(b.createdAt) ? -1 : 1) || [];

  return <NotesListContainer>
    <NotesListAddButtonContainer>
      You have {notes.length} note(s).
      <AddNotePopover
        onAddedNote={onAddedNote} 
        jobApp={jobApp} 
      />
    </NotesListAddButtonContainer>
    {notes.length > 0 && <StyledCollapse
      expandIcon={({ isActive }) => <FontAwesomeIcon 
        icon={faCaretRight} 
        style={isActive
          ? {
            transform: 'rotate(90deg)'
          } : {}}/> 
      }
    >
      {notes.map((note) => (
        <Note 
          key={note.id}
          note={note} 
          onUpdated={(updatedNote) => onUpdatedNote(updatedNote)}
          onRemoved={() => onRemovedNote(note)}
        />
      ))}
    </StyledCollapse>}
  </NotesListContainer>
}

export default NotesList;
