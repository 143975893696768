import { postFormForJson, StatusCodeError } from '../xhr';

export default async function xhrCreateDocument(
  name: string, file: File, token: string,
) {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('document', file);
  const endpoint = '/documents';
  try {
    const createdDoc = await postFormForJson(endpoint, formData, token);
    return createdDoc;
  } catch (err) {
    if (err instanceof StatusCodeError && err.code === 403) {
      const { message } = await err.response.json();
      throw new Error(message);
    }
    throw err;
  }
}
