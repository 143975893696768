import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import JobAppCard from '../Card';
import DroppableTypes from '../../../constants/DroppableTypes';
import JobAppColumnType from '../../../types/JobAppColumn';
import JobApp from '../../../types/JobApp';
import AddJobAppModal from '../AddJobAppModal';
import {
  ColumnAddJobButtonStyles,
  ColumnBodyStyles,
  ColumnDroppableAreaStyles,
  ColumnStyles
} from '../Column/Column.styles';
import ColumnSettingsDropdown from '../ColumnSettingsDropdown';
import SimpleBar from 'simplebar-react';
import {ColumnOrganizationHeaderStyles} from './ColumnOrganization.styles';
import { Typography } from 'antd';
interface ColumnOrganizationProps {
  index: number;
  column: JobAppColumnType;
  jobApps: JobApp[];
  disableDrag: boolean;
}

const ColumnOrganization: React.FC<ColumnOrganizationProps> = ({
  index, column, jobApps, disableDrag,
}) => {
  const { name } = column;
  const [addingJobApp, setAddingJobApp] = useState(false);

  return (
    <>
      <Draggable
        index={index}
        draggableId={column.id}
        isDragDisabled={true}
      >
        {(columnProvided) => (
          <ColumnStyles
            ref={columnProvided.innerRef}
            {...columnProvided.draggableProps}

          >
            <AddJobAppModal
              column={column}
              visible={addingJobApp}
              onClose={() => setAddingJobApp(false)}
            />
            <ColumnOrganizationHeaderStyles
              {...columnProvided.dragHandleProps}
            >
              <div style={{width: '100%'}}>
                <SimpleBar style={{width: '100%', paddingBottom: '0.25rem'}}>
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: 600,
                    }}
                  >
                    {name}
                  </span>
                </SimpleBar>
                <Typography.Text type="secondary" style={{
                  margin: 0
                }}>
                  {`${jobApps.length} job${jobApps.length === 1 ? '' : 's'}`}
                </Typography.Text>
              </div>
              <ColumnSettingsDropdown
                column={column}
                jobApps={jobApps}
                excludeOptions={['delete', 'rename']}
              />
            </ColumnOrganizationHeaderStyles>
            <ColumnAddJobButtonStyles
              onClick={() => setAddingJobApp(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </ColumnAddJobButtonStyles>
            <ColumnBodyStyles>

              <Droppable droppableId={column.id} type={DroppableTypes.JOB}>
                {(provided, snapshot) => (
                  <ColumnDroppableAreaStyles
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    {jobApps.map((app, appIndex) => (
                      <JobAppCard
                        key={app.id}
                        column={column}
                        jobApp={app}
                        index={appIndex}
                        disableDrag={disableDrag}
                      />
                    ))}
                    {provided.placeholder}
                  </ColumnDroppableAreaStyles>
                )}
              </Droppable>
            </ColumnBodyStyles>
          </ColumnStyles>
        )}
      </Draggable>
    </>
  );
};

export default ColumnOrganization;
