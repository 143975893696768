import {
  createContext,
  useCallback,
  useContext, useEffect, useState,
} from 'react';
import { UserContext } from './UserProvider';
import xhrGetMemberNotifications from '../adapters/notifications/xhrGetMemberNotifications';
import MemberNotification from '../types/MemberNotification';
import xhrUpdateMemberNotification from '../adapters/notifications/xhrUpdateMemberNotifications';
import dayjs from 'dayjs';
import xhrReadAllMemberNotifications from '../adapters/notifications/xhrReadAllMemberNotifications';

type MemberNotificationsContextData = {
  error: string;
  notifications: MemberNotification[]|null;
  getNotifications: () => Promise<MemberNotification[]>
  updateNotification: (id: string, updates: { status?: 'seen'|'unseen' }) => Promise<MemberNotification>;
  setNotifications: (notifications: MemberNotification[]) => void;
  readAllNotifications: () => Promise<void>
  refreshNotifications: () => Promise<void>
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const MemberNotificationsContext = createContext<MemberNotificationsContextData>({
  notifications: null,
  error: '',
  getNotifications: defaultFunction,
  updateNotification: defaultFunction,
  setNotifications: defaultFunction,
  readAllNotifications: defaultFunction,
  refreshNotifications: defaultFunction,
});

export const MemberNotificationsProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useContext(UserContext);
  const [notifications, setNotifications] = useState<MemberNotification[]|null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const getInitialNotifications = async () => {
      try {
        const fetchedNotifications = await getNotifications();
        setNotifications(fetchedNotifications);
      } catch (err) {
        setError((err as Error).message)
        console.error(err);
      }
    }

    getInitialNotifications()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getNotifications: MemberNotificationsContextData['getNotifications'] = useCallback(async () => {
    const accessToken = await getAccessToken();
    const fetchedNotifications = await xhrGetMemberNotifications(accessToken, {
      filters: {
        scheduledAt: {
          gte: dayjs.utc().subtract(30, 'day').toISOString(),
          lte: dayjs.utc().toISOString(),
        }
      }
    });
    return fetchedNotifications
  }, [getAccessToken])

  const updateNotification: MemberNotificationsContextData['updateNotification'] = useCallback(async (id, updates) => {
    const accessToken = await getAccessToken();
    const updatedNotification = await xhrUpdateMemberNotification(id, updates, accessToken);

    const updatedNotifications = notifications?.map((notification) => {
      if (notification.id === id) {
        return updatedNotification;
      }
      return notification;
    })
  
    setNotifications(updatedNotifications || null);
    return updatedNotification;
  }, [getAccessToken, notifications])

  const readAllNotifications: MemberNotificationsContextData['readAllNotifications'] = useCallback(async () => {
    const accessToken = await getAccessToken();
    await xhrReadAllMemberNotifications(accessToken);
    setNotifications(notifications?.map((notification) => ({
      ...notification,
      status: 'seen',
    })) || null);
  }, [getAccessToken, notifications])

  const refreshNotifications: MemberNotificationsContextData['refreshNotifications'] = useCallback(async () => {
    const notifs = await getNotifications()
    setNotifications(notifs);
  }, [getNotifications])
  
  return (
    <MemberNotificationsContext.Provider value={{
      updateNotification,
      error,
      notifications,
      getNotifications,
      setNotifications,
      readAllNotifications,
      refreshNotifications
    }}
    >
      {children}
    </MemberNotificationsContext.Provider>
  );
};
