import { stringify } from 'qs';
import MemberNotification from '../../types/MemberNotification';
import { getJson } from '../xhr';


export default function xhrGetMemberNotifications(
  accessToken: string,
  options: {
    filters: {
      scheduledAt: {
        gte: string
        lte: string
      }
    }
  }
): Promise<MemberNotification[]> {
  const filtersQuery = stringify(options || {})

  return getJson(`/users/@me/member-notifications?${filtersQuery}`, accessToken);
}
