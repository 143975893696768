import styled from "styled-components"
import { Avatar, Button } from 'antd';
import themeColors from "../../../constants/themeColors";
import { transparentize } from "polished";

export const AppViewDropdownUserAvatar = styled(Avatar)`
  background: ${themeColors.brand.primary};
  margin-right: 10px;
  min-width: 2rem;
  min-height: 2rem;
  height: 2rem;
  width: 2rem;
`;

export const AppViewDropdownButton = styled(Button)`
  overflow: hidden;
  font-size: 1rem;
  text-overflow: ellipsis;
  text-align: left;
  color: ${themeColors.background.offWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  height: 3.25rem;
  border-radius: 10px;
  border: solid 2px ${transparentize(0.5, themeColors.background.gray)} !important;
`
