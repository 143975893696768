import {
  Button as AntButton, Collapse, Form, Input, Popconfirm, Space, Typography
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCheck, faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Store } from 'antd/lib/form/interface';
import themeColors from '../../../../constants/themeColors';
import AddInterviewPopover from './AddInterviewPopover';
import JobAppInterview from '../../../../types/JobAppInterview';
import JobApp from '../../../../types/JobApp';
import DatePicker from '../../../DatePicker';
import { DATE_FORMAT_WITH_TIME } from '../../../../constants/dateTimeFormats';
import { JobAppBoardContext } from '../../../../contexts/JobAppBoardProvider';
import { JobAppInterviewContext } from '../../../../contexts/JobAppInterviewProvider';
import InterviewItem from './InterviewItem';
import notifyError from '../../../../util/notifyError';
import { MemberNotificationsContext } from '../../../../contexts/MemberNotificationsProvider';
import * as Sentry from '@sentry/react'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  h4 {
    color: ${themeColors.background.dark};
  }
`;

const StyledCollapse = styled(Collapse)`
  .anticon {
    color: ${themeColors.brand.primaryLight} !important;
  }
  .ant-collapse-item > .ant-collapse-header {
    color: ${themeColors.background.dark};
  }

  .interview-edit-buttons {
    opacity: 0;
    button {
      color: ${themeColors.background.gray};
      &:hover {
        color: ${themeColors.background.dark};
      }
    }
  }
  .ant-collapse-item:hover {
    .interview-edit-buttons {
      opacity: 1;
    }
  }
`;

function getUpcomingInterviews(interviews: JobAppInterview[]) {
  return interviews.reduce((acc, current) => {
    if (dayjs().isBefore(dayjs(current.date))) {
      return acc + 1;
    }
    return acc;
  }, 0);
}


interface InterviewSectionProps {
  jobApp: JobApp;
}

const InterviewSection: React.FC<InterviewSectionProps> = ({
  jobApp,
}) => {
  const [interviews, setInterviews] = useState<JobAppInterview[]>([]);
  const [editingInterviewId, setEditingInterviewId] = useState('');
  const numberOfUpcoming = getUpcomingInterviews(interviews);
  const { activeBoardId } = useContext(JobAppBoardContext);
  const {
    getJobAppInterviews,
    createInterview,
    updateJobAppInterview,
    deleteInterview,
  } = useContext(JobAppInterviewContext);
  const {
    refreshNotifications,
  } = useContext(MemberNotificationsContext)

  useEffect(() => {
    getJobAppInterviews(jobApp.id).then(({ data }) => setInterviews(data));
  }, [jobApp.id, jobApp.columnId, getJobAppInterviews]);

  const updateInterview = async (interview: JobAppInterview, details: Store) => {
    const { id: interviewId } = interview

    const currentDate = dayjs(interview.date).utc().toISOString()
    const newDate = dayjs(details.date).utc().toISOString()
    const dateIsDifferent = currentDate !== newDate
  
    try {
      const toSave = {
        ...details,
        ...(dateIsDifferent && { date: newDate }),
      };
      const updatedInterview = await updateJobAppInterview(
        interviewId,
        toSave,
      );
      setInterviews(interviews.map((interview) => {
        if (interview.id === interviewId) {
          return updatedInterview;
        }
        return interview;
      }));
      setEditingInterviewId('');
      if (dateIsDifferent) {
        setTimeout(() => {
          refreshNotifications().catch(err => Sentry.captureException(err))
        }, 2000);
      }
    } catch (err) {
      notifyError('Failed to update interview', (err as Error).message);
    }
  };

  const addInterview = async (date: string, title: string) => {
    if (!activeBoardId) {
      throw new Error('Missing active board for adding interview');
    }
    try {
      const added = await createInterview({
        jobAppId: jobApp.id,
        title,
        date
      });
      setInterviews([
        added,
        ...interviews,
      ]);
      setTimeout(() => {
        refreshNotifications().catch(err => Sentry.captureException(err))
      }, 2000)
    } catch (err) {
      notifyError('Failed to add new interview', (err as Error).message);
    }
  };

  const onDeleteInterview = async (interviewId: string) => {
    if (!activeBoardId) {
      throw new Error('Missing active board for deleting interview');
    }
    try {
      await deleteInterview(interviewId);
      setInterviews(interviews.filter((interview) => interview.id !== interviewId));
    } catch (err) {
      notifyError('Failed to delete interview', (err as Error).message);
    }
  };

  const stopPropagationOnEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  const InterviewEditHeader = (interview: JobAppInterview) => {
    const { date, title } = interview;

    return (
      <Form
        onClick={(e) => e.stopPropagation()}
        onFinish={(data: Store) => updateInterview(interview, data)}
        style={{
          display: 'inline-block'
        }}
      >
        <Space style={{ display: 'flex', flexWrap: 'wrap' }} align="baseline">
          <Form.Item
            name="date"
            style={{ margin: 0, width: '13rem' }}
            rules={[{
              required: true,
              message: 'Interview date is required',
            }]}
            initialValue={dayjs(date)}
          >
            <DatePicker
              placeholder="Interview date"
              showTime={{ format: 'h:mm A' }}
              format={DATE_FORMAT_WITH_TIME}
            />
          </Form.Item>
          <Form.Item
            name="title"
            rules={[{
              required: true,
              message: 'Interview title is required',
            }]}
            style={{ margin: 0, width: '13rem' }}
            initialValue={title}
          >
            <Input placeholder="Interview title" onKeyPress={stopPropagationOnEnter} />
          </Form.Item>
          <Form.Item style={{ margin: 0, width: '5rem' }}>
            <Space>
              <AntButton
                type="text"
                htmlType="submit"
                size="small"
                icon={(
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: themeColors.semantic.success }}
                  />
                )}
                onKeyPress={stopPropagationOnEnter}
              />
              <AntButton
                type="text"
                htmlType="button"
                size="small"
                icon={(
                  <FontAwesomeIcon
                    icon={faTimes}
                  />
                )}
                onClick={() => setEditingInterviewId('')}
                onKeyPress={stopPropagationOnEnter}
              />
            </Space>
          </Form.Item>
        </Space>
      </Form>
    )};

  // Used to calculate if a interview is close to today
  const todayMoment = dayjs();
  // Upcoming interviews should be at the top
  const sortedInterviewsByDate = [...interviews]
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <>
      <Header>
        <Typography.Text>
          {`You have ${numberOfUpcoming} upcoming interview(s).`}
        </Typography.Text>
        <AddInterviewPopover
          onInterviewAdded={({ date, title }) => addInterview(date.toISOString(), title)}
        />
      </Header>
      {sortedInterviewsByDate.length > 0 && <StyledCollapse
        expandIcon={({ isActive }) => <FontAwesomeIcon icon={faCaretRight} style={isActive ? {
          transform: 'rotate(90deg)'
        } : {}}/> }
      >
        {sortedInterviewsByDate.map((interview) => {
          const dateMoment = dayjs(interview.date);
          const daysUntil = dateMoment.diff(todayMoment, 'days');
          const isSoon = daysUntil >= 0 && daysUntil < 2;

          return (
            <Collapse.Panel
              key={interview.id}
              header={editingInterviewId === interview.id ? (
                InterviewEditHeader(interview)
              ) : (
                <span style={{
                  color: isSoon
                    ? themeColors.semantic.danger
                    : themeColors.background.dark,
                }}
                >
                  <span style={{ paddingRight: '1rem' }}>
                    {dateMoment.format(DATE_FORMAT_WITH_TIME)}
                  </span>
                  <span style={{fontWeight: 600}}>
                    {interview.title}
                  </span>
                </span>
              )}
              extra={(
                <Space className="interview-edit-buttons" hidden={editingInterviewId === interview.id}>
                  <AntButton 
                    type="text"
                    size="small"
                    icon={<FontAwesomeIcon icon={faEdit} />} 
                    onClick={(e) => {
                      setEditingInterviewId(interview.id)
                      e.stopPropagation()
                    }}
                  />
                  <Popconfirm
                    title="Are you sure you want to delete this interview?"
                    onConfirm={() => onDeleteInterview(interview.id)}
                  >
                    <AntButton 
                      type="text"
                      size="small"
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={e => e.stopPropagation()}
                    />
                  </Popconfirm>
                </Space>
              )}
            >
              <InterviewItem jobApp={jobApp} interview={interview} />
            </Collapse.Panel>
          );
        })}
      </StyledCollapse>}
    </>
  );
};

export default InterviewSection;
