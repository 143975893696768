import { patch } from "../../../adapters/xhr";
import { GoalStatus } from "../types";

export interface UpdateOrganizationGoalInput {
  id: string
  data: {
    status: GoalStatus
  }
}

export const updateOrganizationGoal = async (options: UpdateOrganizationGoalInput, accessToken: string): Promise<void> => {
  await patch(
    `/user-checklist-tasks/${options.id}`,
    options.data,
    accessToken
  )
}
