import { deleteFor } from '../xhr';

export default function xhrDeleteJobApp(
  boardId: string,
  columnId: string,
  jobAppId: string,
  accessToken: string,
) {
  return deleteFor(
    `/jobappboards/${boardId}/columns/${columnId}/jobapps/${jobAppId}`,
    accessToken,
  );
}
