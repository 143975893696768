import { useRef } from "react";
import themeColors from "../../../../../../constants/themeColors";
import { 
  InterviewQuestionAnswerTextAreaStyled
} from "./InterviewQuestionAnswerTextArea.styles";

interface Props {
  text: string
  onChange: (text: string) => void
  textAreaStyles?: React.CSSProperties
}

const InterviewQuestionAnswerTextArea: React.FC<Props> = ({
  text,
  onChange,
  textAreaStyles,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  return <InterviewQuestionAnswerTextAreaStyled
    spellCheck={false}
    ref={textAreaRef}
    onChange={(e) => onChange(e.target.value)}
    value={text}
    autoSize
    style={{
      background: 'white',
      border: `solid 1px ${themeColors.background.mediumGray}`,
      ...textAreaStyles,
    }}
  />
}

export default InterviewQuestionAnswerTextArea;
