import React, { useCallback, useContext } from 'react';
import { UserContext } from './UserProvider';
import JobAppInterview from '../types/JobAppInterview';
import xhrUpdateJobAppInterview from '../adapters/jobAppInterviews/xhrUpdateJobAppInterview';
import xhrDeleteInterview from '../adapters/jobAppInterviews/xhrDeleteInterview';
import xhrGetJobAppInterviews from '../adapters/jobAppInterviews/xhrGetJobAppInterviews';
import JobApp from '../types/JobApp';
import xhrGetJobAppInterview from '../adapters/jobAppInterviews/xhrGetJobAppInterview';
import xhrCreateInterview from '../adapters/interviews/xhrCreateInterview';

type JobAppInterviewContextData = {
  getJobAppInterviews: (
    jobAppId: string,
  ) => Promise<{total: number, data: JobAppInterview[]}>
  getUserInterviewWithJobApps: (id: string) => Promise<JobAppInterview & { jobApp: JobApp}>
  createInterview: (details: {
    title: string,
    jobAppId: string,
    date :string
  }) => ReturnType<typeof xhrCreateInterview>
  updateJobAppInterview: (
    interviewId: string,
    details: Partial<JobAppInterview>
  ) => Promise<JobAppInterview>
  deleteInterview: (
    interviewId: string,
  ) => Promise<void>
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const JobAppInterviewContext = React.createContext<JobAppInterviewContextData>({
  getJobAppInterviews: defaultFunction,
  getUserInterviewWithJobApps: defaultFunction,
  createInterview: defaultFunction,
  updateJobAppInterview: defaultFunction,
  deleteInterview: defaultFunction,
});

export const JobAppInterviewProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useContext(UserContext);

  const getJobAppInterviews: JobAppInterviewContextData['getJobAppInterviews'] = useCallback(async (
    jobAppId,
  ) => {
    const accessToken = await getAccessToken();
    return xhrGetJobAppInterviews(accessToken, {
      limit: 100,
      offset: 0,
      filters: {
        jobAppId,
      }
    });
  }, [getAccessToken]);

  const getUserInterviewWithJobApps: JobAppInterviewContextData['getUserInterviewWithJobApps'] = useCallback(async (id) => {
    const accessToken = await getAccessToken()
    return xhrGetJobAppInterview(accessToken, id)
  }, [getAccessToken])

  const createInterview: JobAppInterviewContextData['createInterview'] = useCallback(async (details) => {
    const accessToken = await getAccessToken()
    return xhrCreateInterview(details, accessToken)
  }, [getAccessToken])

  const updateJobAppInterview: JobAppInterviewContextData['updateJobAppInterview'] = useCallback(async (
    interviewId,
    details,
  ) => {
    const accessToken = await getAccessToken();
    return xhrUpdateJobAppInterview(interviewId, details, accessToken);
  }, [getAccessToken]);

  const deleteInterview: JobAppInterviewContextData['deleteInterview'] = useCallback(async (
    interviewId,
  ) => {
    const accessToken = await getAccessToken();
    await xhrDeleteInterview(interviewId, accessToken);
  }, [getAccessToken]);

  return (
    <JobAppInterviewContext.Provider value={{
      getJobAppInterviews,
      getUserInterviewWithJobApps,
      createInterview,
      updateJobAppInterview,
      deleteInterview,
    }}
    >
      {children}
    </JobAppInterviewContext.Provider>
  );
};
