/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary  */
import React, {
  useContext,
  useRef, useState,
} from 'react';
import {
  Droppable, DragDropContext, DropResult,
} from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import Column from '../Column/Column';
import DroppableTypes from '../../../constants/DroppableTypes';
import AddColumnBox from '../AddColumnBox';
import AddJobColumnModal from '../AddJobColumnModal';
import SearchBar from '../../SearchBar';
import EditJobAppModal from '../EditJobAppModal';
import BoardSkeleton from '../BoardSkeleton';
import BoardLogic from '../Board.logic';
import notifyError from '../../../util/notifyError';
import { AppOrganizationViewContext } from '../../../contexts/AppOrganizationViewProvider';
import ColumnOrganization from '../ColumnOrganization/ColumnOrganization';
import BoardSelector from '../BoardSelector';
import BoardSettingsDropdown from './BoardSettingsDropdown/BoardSettingsDropdown';
import {
  BoardColumnContainer, 
  BoardContainer,
  BoardDroppableColumnContainer,
  BoardManagementContainer,
  BoardNoSearchResultsFoundContainer,
  BoardPaddedInnerWrap,
  BoardSearchContainer
} from './Board.styles';
import { Redirect } from '@reach/router';
import pages from '../../../constants/pages';
import { OrganizationGoals } from '../../../features/goals';
import { Badge, Button, Grid, Space } from 'antd';

interface BoardProps {
  boardId?: string
}

const goalsSidebarBreakpoint = 'xxl' as const

const Board: React.FC<BoardProps> = ({ boardId }) => {
  const {
    jobAppsDisplayed,
    columnsDisplayed,
    setSearchTerm,
    searchTerm,
    board,
    onJobAppMoved,
    onJobAppColumnMoved,
  } = BoardLogic({ boardId });
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [addingJobColumn, setAddingJobColumn] = useState(false);
  const { selectedOrganization } = useContext(AppOrganizationViewContext)
  const screens = Grid.useBreakpoint();
  const goalsSidebarRef = useRef<HTMLDivElement>(null);
  const isOnSmallScreen = !screens[goalsSidebarBreakpoint]

  function scrollToRight() {
    scrollContainer.current?.scrollBy({
      left: (columnsDisplayed || []).length * 350,
      behavior: 'smooth',
    });
  }

  async function onDragEndRouter(result: DropResult) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    if (result.type === DroppableTypes.COLUMN) {
      try {
        await onJobAppColumnMoved(result);
      } catch (err) {
        notifyError('Unable to move list', (err as Error).message);
      }
    } else if (result.type === DroppableTypes.JOB) {
      try {
        await onJobAppMoved(result);
      } catch (err) {
        notifyError('Unable to move job app', (err as Error).message);
      }
    }
  }


  const showSearchJobsFound = (
    (columnsDisplayed || []).map((column, columnIndex) => (
      React.createElement(!selectedOrganization ? Column : ColumnOrganization, {
        key: column.id,
        index: columnIndex,
        column,
        jobApps: (jobAppsDisplayed || []).filter((jobApp) => jobApp.columnId === column.id),
        disableDrag: !!searchTerm,
      })
    
    ))
  );

  const noJobsFound = (
    <BoardNoSearchResultsFoundContainer>
      {`No matching results for "${searchTerm}"`}
    </BoardNoSearchResultsFoundContainer>
  );

  if (columnsDisplayed === null || jobAppsDisplayed === null) {
    return (
      <BoardContainer style={{
        paddingTop: '30px'
      }}>
        <BoardSkeleton />
      </BoardContainer>
    );
  }

  const isOrgBoard = board && !!board.organizationId;
  const isPersonalBoard = board && !board.organizationId;
  if (selectedOrganization && isPersonalBoard) {
    return <Redirect to={pages.BOARD} noThrow />;
  }

  if (!selectedOrganization && isOrgBoard) {
    return <Redirect to={pages.BOARD} noThrow />;
  }

  return (
    <div style={{
      display: 'flex',
      width: isOnSmallScreen ? '100%' : 'calc(100% - 375px)',
      overflow: 'auto',
      flex: 1
    }}>
      <BoardContainer>
        <EditJobAppModal />
        <Helmet>
          <title>Job Tracking Board</title>
        </Helmet>
        <BoardManagementContainer>
          <Space>
            <BoardSelector />
            {board && <BoardSettingsDropdown board={board} isOrganization={!!selectedOrganization} />}
          </Space>
          <OrganizationGoals
            isOnSmallScreen={isOnSmallScreen}
            sidebarContainerRef={goalsSidebarRef}
            renderOpenButton={({onClick}) => {
              if (!isOnSmallScreen) {
              // Should already show and be be fixed to the right of the screen
                return null
              } 
           
              return  <Badge
                dot
              >
                <Button type="default" onClick={onClick}>
                Goals
                </Button>
              </Badge>
            }} />
        </BoardManagementContainer>
        <BoardSearchContainer>
          <SearchBar
            placeholder="Search your jobs"
            searchFunction={setSearchTerm}
            clearFunction={() => setSearchTerm('')}
            style={{ maxWidth: '550px' }}
          />
        </BoardSearchContainer>
        <DragDropContext onDragEnd={onDragEndRouter}>
          <BoardColumnContainer ref={scrollContainer}>
            <AddJobColumnModal
              boardId={boardId}
              onAdded={scrollToRight}
              visible={addingJobColumn}
              onClose={() => setAddingJobColumn(false)}
            />
            {
              (searchTerm && !jobAppsDisplayed.length)
                ? noJobsFound
                : (
                  <BoardPaddedInnerWrap>
                    <>
                      <Droppable
                        droppableId="cols"
                        direction="horizontal"
                        type={DroppableTypes.COLUMN}
                      >
                        {(provided) => (
                          <BoardDroppableColumnContainer
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {showSearchJobsFound}
                            {provided.placeholder}
                          </BoardDroppableColumnContainer>
                        )}
                      </Droppable>
                      {!searchTerm && !selectedOrganization && (
                        <AddColumnBox
                          onClickAddColumn={() => setAddingJobColumn(true)}
                        />
                      )}
                    </>
                  </BoardPaddedInnerWrap>
                )
            }
          </BoardColumnContainer>
        </DragDropContext>
      </BoardContainer>
      <div ref={goalsSidebarRef}>
      </div>
    </div>
  );
};

export default Board;
