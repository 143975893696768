import { notification } from 'antd';
import * as Sentry from '@sentry/react'

export default function notifyError(title: string, error: string|Error = '') {
  notification.error({
    placement: 'bottomRight',
    message: title,
    description: typeof error === 'string' ? error : error.message,
  });
  if (error instanceof Error) {
    Sentry.captureException(error);
  }
}
