import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';
import ColumnSkeleton from './ColumnSkeleton';

const SearchContainer = styled.div`
  margin: 0 15px 15px 0px;
  max-width: 550px;
`;

const SearchInputSkeleton = styled(Skeleton)`
  h3 {
    margin-bottom: 0 !important;
    margin-top: 0px !important;
    height: 1.5rem !important; 
  }
`;

const Columns = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const BoardSkeleton = () => {
  const skeletonColumns = [];

  // 335 is the width of a column
  const roughColumnCount = Math.ceil(window.innerWidth / 335) - 1;
  for (let i = 0; i < roughColumnCount; i += 1) {
    skeletonColumns.push(<ColumnSkeleton key={i} />);
  }

  return (
    <>
      <SearchContainer>
        <SearchInputSkeleton title active paragraph={false} />
      </SearchContainer>
      <Columns>
        {skeletonColumns}
      </Columns>
    </>
  );
};

export default BoardSkeleton;
