/**
 * Used for detecting whether the user has the app as the currently active/visible tab
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API?redirectlocale=en-US&redirectslug=DOM%2FUsing_the_Page_Visibility_API
 *
 * These defaults are not guaranteed to be available, but it wouldn't break anything if we did
 * return them.
 */
const browserTabVisiblityConstants = {
  hidden: 'hidden',
  eventName: 'visibilitychange',
};

// Special cases for different browsers
if ('msHidden' in document) {
  browserTabVisiblityConstants.hidden = 'msHidden';
  browserTabVisiblityConstants.eventName = 'msvisibilitychange';
} else if ('webkitHidden' in document) {
  browserTabVisiblityConstants.hidden = 'webkitHidden';
  browserTabVisiblityConstants.eventName = 'webkitvisibilitychange';
}

export default browserTabVisiblityConstants;
