import { Collapse } from "antd";
import styled from "styled-components";
import themeColors from "../../../../../constants/themeColors";

export const NoteCollapsePanel = styled(Collapse.Panel)`
  > .ant-collapse-header {
    width: 100%;
    display: flex;
    align-items: center;
  }
`

export const NoteExtraButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button {
    color: ${themeColors.background.gray};
  }
  &:hover, &:focus, &:focus-within {
    .note-buttons {
      opacity: 1;
    }
  }
`
