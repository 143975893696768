import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import getDocuments from '../adapters/documents/getDocuments';
import xhrCreateDocument from '../adapters/documents/xhrCreateDocument';
import xhrUpdateDocument from '../adapters/documents/xhrUpdateDocument';
import { deleteFor } from '../adapters/xhr';
import { UserContext } from './UserProvider';

export type Document = {
  id: string;
  userId: string;
  url: string;
  name: string;
  createdAt: string;
};

type DocumentContextData = {
  documents: Document[]|null
  deleteDocument: (documentId: string) => Promise<void>
  updateDocument: (documentId: string, updates: { name: string }) => Promise<void>
  createDocument: (name: string, file: File) => Promise<Document>
};

export const DocumentContext = React.createContext<DocumentContextData>({
  documents: [],
  deleteDocument: async () => {},
  updateDocument: async () => {},
  createDocument: async () => ({}) as any,
});

export const DocumentProvider: React.FC = ({ children }) => {
  const [documents, setDocuments] = useState<Document[]|null>(null);
  const { getAccessToken } = useContext(UserContext);

  useEffect(() => {
    getAccessToken().then(getDocuments).then(setDocuments);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteDocument = useCallback(async (documentId: string) => {
    const token = await getAccessToken();
    await deleteFor(`/documents/${documentId}`, token);
    setDocuments((curDocuments) => curDocuments?.filter(({ id }) => id !== documentId) || null);
  }, [getAccessToken]);

  const updateDocument = useCallback(async (documentId: string, updates: { name: string }) => {
    const token = await getAccessToken();
    const updatedDoc = await xhrUpdateDocument(documentId, updates, token);
    setDocuments((curDocuments) => curDocuments
      ?.map((doc) => (doc.id === documentId ? updatedDoc : doc)) || []);
  }, [getAccessToken]);

  const createDocument = useCallback(async (name: string, file: File) => {
    const token = await getAccessToken();
    const createdDoc = await xhrCreateDocument(name, file, token);
    setDocuments((curDocuments) => [
      ...curDocuments || [],
      createdDoc,
    ]);
    return createdDoc;
  }, [getAccessToken]);

  return (
    <DocumentContext.Provider value={{
      documents,
      deleteDocument,
      updateDocument,
      createDocument,
    }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
