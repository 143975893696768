import JobAppInterviewQuestion from '../../types/JobAppInterviewQuestion';
import { postForJson } from '../xhr';

export default function xhrCreateInterviewQuestion(
  accessToken: string,
  data: Partial<JobAppInterviewQuestion>
) {
  return postForJson(
    `/interview-questions`,
    data,
    accessToken,
  )
}
