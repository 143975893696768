import styled from "styled-components";
import themeColors from "../../../../../constants/themeColors";

export const ContactFormContainer = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  border: solid 1px ${themeColors.background.offWhite};
  margin-bottom: 1rem;
  background: white;
  .ant-form-item {
    margin-bottom: 0.5rem;
  }
`
