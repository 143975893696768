import React, { useCallback, useContext } from 'react';
import { UserContext } from './UserProvider';
import JobAppInterviewQuestion from '../types/JobAppInterviewQuestion';
import xhrGetInterviewQuestions from '../adapters/interviewQuestions/xhrGetInterviewQuestions';
import xhrUpdateInterviewQuestion from '../adapters/interviewQuestions/xhrUpdateInterviewQuestion';
import xhrCreateInterviewQuestion from '../adapters/interviewQuestions/xhrCreateInterviewQuestion';
import xhrDeleteInterviewQuestion from '../adapters/interviewQuestions/xhrDeleteInterviewQuestion';

type InterviewQuestionContextData = {
  createInterviewQuestion: (
    data: Partial<JobAppInterviewQuestion>
  ) => Promise<JobAppInterviewQuestion>;
  getInterviewQuestions: (
    interviewId: string,
  ) => Promise<JobAppInterviewQuestion[]>
  updateInterviewQuestion: (
    questionId: string,
    updates: Partial<JobAppInterviewQuestion>
  ) => Promise<JobAppInterviewQuestion>
  deleteInterviewQuestion: (questionId: string) => Promise<void>
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const InterviewQuestionContext = React.createContext<InterviewQuestionContextData>({
  createInterviewQuestion: defaultFunction,
  getInterviewQuestions: defaultFunction,
  updateInterviewQuestion: defaultFunction,
  deleteInterviewQuestion: defaultFunction,
});

export const InterviewQuestionProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useContext(UserContext);

  const createInterviewQuestion: InterviewQuestionContextData['createInterviewQuestion'] = useCallback(async (data) => {
    const accessToken = await getAccessToken()
    return xhrCreateInterviewQuestion(accessToken, data);
  }, [getAccessToken]);

  const getInterviewQuestions: InterviewQuestionContextData['getInterviewQuestions'] = useCallback(async (
    interviewId
  ) => {
    const accessToken = await getAccessToken();
    return xhrGetInterviewQuestions(accessToken, interviewId);
  }, [getAccessToken]);

  const updateInterviewQuestion: InterviewQuestionContextData['updateInterviewQuestion'] = useCallback(async (
    interviewId,
    updates
  ) => {
    const accessToken = await getAccessToken()
    return xhrUpdateInterviewQuestion(accessToken, interviewId, updates)
  }, [getAccessToken])

  const deleteInterviewQuestion: InterviewQuestionContextData['deleteInterviewQuestion'] = useCallback(async (questionId) => {
    const accessToken = await getAccessToken()
    return xhrDeleteInterviewQuestion(accessToken, questionId)
  }, [getAccessToken])

  return (
    <InterviewQuestionContext.Provider value={{
      createInterviewQuestion,
      getInterviewQuestions,
      updateInterviewQuestion,
      deleteInterviewQuestion,
    }}
    >
      {children}
    </InterviewQuestionContext.Provider>
  );
};
