import { faEdit, faEnvelope, faPhone, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Popconfirm, Space } from "antd";
import { useContext, useState } from "react";
import themeColors from "../../../../../constants/themeColors";
import { ContactsContext } from "../../../../../contexts/ContactsProvider";
import Contact from "../../../../../types/Contact";
import JobApp from "../../../../../types/JobApp";
import notifyError from "../../../../../util/notifyError";
import ContactForm from "../ContactForm/ContactForm";
import {
  ContactCardCard,
  ContactCardContactContainer,
  ContactCardHeader,
  ContactCardNotes
} from "./ContactCard.styles";

interface Props {
  contact: Contact
  jobApp: JobApp
  onUpdated: (contact: Contact) => void
  onDeleted?: (contact: Contact) => void
}


const ContactCard: React.FC<Props> = ({
  contact,
  jobApp,
  onUpdated,
  onDeleted,
}) => {
  const { editContact, removeContactFromJobApp } = useContext(ContactsContext);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  
  const onEditContact = async (updates: Partial<Contact>) => {
    try {
      setEditing(true)
      const updatedContact = await editContact(contact.id, updates);
      onUpdated(updatedContact);
    } catch (err) {
      notifyError(`Failed to update contact`, (err as Error).message);
    } finally {
      setEditing(false)
    }
  }

  const onDeleteContact = async () => {
    try {
      setDeleting(true)
      await removeContactFromJobApp(jobApp.id, contact.id);
      onDeleted?.(contact);
    } catch (err) {
      notifyError(`Failed to delete contact`, (err as Error).message);
    } finally {
      setDeleting(false)
    }
  }

  const placeholderText = (text: string) => <span style={{
    color: themeColors.background.gray
  }}>
    {text}
  </span>

  if (editing) {
    return <ContactForm 
      onSubmit={onEditContact}
      initialValues={contact}
      onCancel={() => setEditing(false)}
      onClickDelete={() => onDeleteContact()}
      deleting={deleting}
    />
  }

  return <Space direction="vertical">
    <ContactCardCard>
      <Space>
        <Button 
          type="text"
          icon={<FontAwesomeIcon icon={faEdit} />} 
          onClick={() => setEditing(true)}
        />
        <Popconfirm
          title="Are you sure you want to remove this contact?"
          onConfirm={onDeleteContact}
          okButtonProps={{
            disabled: deleting
          }}
        >
          <Button 
            type="text"
            disabled={deleting}
            icon={<FontAwesomeIcon icon={faTrash} />}
          />
        </Popconfirm>
      </Space>
      <ContactCardHeader className="header-container">
        <h2>{contact.name}</h2>
        <span>{contact.company || placeholderText('No company')}</span>
        <span>{contact.companyPosition || placeholderText('No position')}</span>
      </ContactCardHeader>
      <ContactCardContactContainer>
        <Space>
          <FontAwesomeIcon icon={faPhone} />
          <span>{contact.phone || placeholderText('No phone')}</span>
        </Space>
        <Space>
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{contact.email || placeholderText('No email')}</span>
        </Space>
      </ContactCardContactContainer>
      <Divider style={{
        margin: 0,
      }} />
      <ContactCardNotes>
        {contact.notes || placeholderText('No notes')}
      </ContactCardNotes>
    </ContactCardCard>
  </Space>
}

export default ContactCard;
