import React, { useCallback, useContext, useState } from 'react';
import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { UserContext } from './UserProvider';
import JobAppColumn from '../types/JobAppColumn';
import xhrGetJobAppColumns from '../adapters/jobAppColumns/xhrGetJobAppColumns';
import xhrUpdateJobAppColumn from '../adapters/jobAppColumns/xhrUpdateJobAppColumn';
import xhrCreateJobAppColumn from '../adapters/jobAppColumns/xhrCreateJobAppColumn';
import xhrDeleteJobAppColumn from '../adapters/jobAppColumns/xhrDeleteJobAppColumn';

type ColumnMovedDetails = {
  sourceColumnIndex: number,
  destColumnIndex: number,
  columnId: string;
  boardId: string;
};

type JobAppColumnContextData = {
  jobAppColumns: JobAppColumn[]|null
  setJobAppColumns: (columns: JobAppColumn[]|null) => void
  createJobAppColumn: (boardId: string, details: Partial<JobAppColumn>) => Promise<JobAppColumn>
  getJobAppColumns: (boardId: string) => Promise<JobAppColumn[]>
  updateJobAppColumn: (
    boardId: string,
    columnId: string,
    data: Partial<JobAppColumn>
  ) => Promise<JobAppColumn>
  deleteJobAppColumn: (
    boardId: string,
    columnId: string
  ) => Promise<void>
  moveColumn: (boardId: string, result: DropResult) => Promise<void>
  updateColumnVisualPosition: (details: ColumnMovedDetails) => void
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const JobAppColumnContext = React.createContext<JobAppColumnContextData>({
  jobAppColumns: null,
  setJobAppColumns: defaultFunction,
  createJobAppColumn: defaultFunction,
  getJobAppColumns: defaultFunction,
  updateJobAppColumn: defaultFunction,
  deleteJobAppColumn: defaultFunction,
  moveColumn: defaultFunction,
  updateColumnVisualPosition: defaultFunction,
});

export const JobAppColumnProvider: React.FC = ({ children }) => {
  const [jobAppColumns, setJobAppColumns] = useState<JobAppColumn[]|null>(null);
  const { getAccessToken } = useContext(UserContext);

  const createJobAppColumn: JobAppColumnContextData['createJobAppColumn'] = async (boardId, details) => {
    const accessToken = await getAccessToken();
    const created = await xhrCreateJobAppColumn(boardId, details, accessToken);
    setJobAppColumns(!jobAppColumns ? null : [
      ...jobAppColumns,
      created,
    ]);
    return created;
  };

  const getJobAppColumns: JobAppColumnContextData['getJobAppColumns'] = useCallback(async (boardId) => {
    const accessToken = await getAccessToken();
    const columns: JobAppColumn[] = await xhrGetJobAppColumns(boardId, accessToken);
    return columns;
  }, [getAccessToken]);

  const updateJobAppColumn: JobAppColumnContextData['updateJobAppColumn'] = useCallback(async (boardId, columnId, updates) => {
    const accessToken = await getAccessToken();
    const newColumn = await xhrUpdateJobAppColumn(boardId, columnId, updates, accessToken);
    setJobAppColumns((curJobAppColumns) => curJobAppColumns
      ?.map((col) => (col.id === columnId ? newColumn : col)) || null);
    return newColumn;
  }, [getAccessToken]);

  const deleteJobAppColumn: JobAppColumnContextData['deleteJobAppColumn'] = useCallback(async (boardId, columnId) => {
    const accessToken = await getAccessToken();
    await xhrDeleteJobAppColumn(boardId, columnId, accessToken);
    setJobAppColumns((curJobAppColumns) => curJobAppColumns
      ?.filter((col) => col.id !== columnId) || null);
  }, [getAccessToken]);

  const updateColumnVisualPosition = useCallback((details: ColumnMovedDetails) => {
    const { sourceColumnIndex, destColumnIndex, columnId } = details;
    const toMove = jobAppColumns?.find((c) => c.id === columnId);
    if (!jobAppColumns || !toMove) {
      return;
    }
    const columnsCopy = [...jobAppColumns];
    columnsCopy.splice(sourceColumnIndex, 1);
    columnsCopy.splice(destColumnIndex, 0, { ...toMove });
    setJobAppColumns(columnsCopy);
  }, [jobAppColumns]);

  const moveColumn = useCallback(async (boardId: string, result: DropResult) => {
    const { source, destination, draggableId } = result;
    const payload = {
      destColumnIndex: (destination as DraggableLocation).index,
      sourceColumnIndex: source.index,
      columnId: draggableId,
      boardId,
    };
    updateColumnVisualPosition(payload);
    try {
      await updateJobAppColumn(boardId, draggableId, {
        position: payload.destColumnIndex,
      });
    } catch (err) {
      // Reverse if it failed
      updateColumnVisualPosition({
        ...payload,
        destColumnIndex: payload.sourceColumnIndex,
        sourceColumnIndex: payload.destColumnIndex,
      });
      throw err;
    }
  }, [updateJobAppColumn, updateColumnVisualPosition]);

  return (
    <JobAppColumnContext.Provider value={{
      jobAppColumns,
      setJobAppColumns,
      createJobAppColumn,
      getJobAppColumns,
      updateJobAppColumn,
      deleteJobAppColumn,
      moveColumn,
      updateColumnVisualPosition,
    }}
    >
      {children}
    </JobAppColumnContext.Provider>
  );
};
