import themeColors from "../../../../constants/themeColors";
import styled from "styled-components";
import DatePicker from "../../../DatePicker";

export const InterviewDateEditableDatePicker = styled(DatePicker)`
  cursor: pointer;
  input {
    cursor: pointer;
    color: ${themeColors.background.gray};
    &:hover, &:focus {
      color: ${themeColors.background.dark};
    }
  }
`
