import { AutoComplete, Avatar } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import searchForCompanies from '../../util/searchForCompanies';

const CompanyListItem = (companyName: string, logoUrl: string) => (
  <div>
    <Avatar src={logoUrl} style={{ marginRight: '1rem' }} size={28} alt="logo" />
    <span>{companyName}</span>
  </div>
);

type AutoCompleteOption = {
  value: string,
  label?: React.ReactNode,
  logoUrl?: string,
};

interface AutoCompleteCompanyInputProps {
  onChange: (option: AutoCompleteOption) => void;
  style?: React.CSSProperties
}

const AutoCompleteCompanyInput: React.FC<AutoCompleteCompanyInputProps> = ({ onChange, style }) => {
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);

  const onSearch = async (searchText: string) => {
    if (searchText) {
      const matchedCompanies = await searchForCompanies(searchText);
      const dedupedCompanies = matchedCompanies.filter((company, index) => {
        return matchedCompanies.findIndex(c => c.name === company.name) === index;
      })
      const newOptions = dedupedCompanies.map((company) => ({
        value: company.name,
        label: CompanyListItem(company.name, company.logo),
        logoUrl: company.logo,
      }));
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  };

  /**
     * useCallback must be use to memoize the debounce function, otherwise the function will be
     * recreated on every render, rendering the debounce useless
     */
  const onSearchDebounce = useCallback(searchText => debounce(onSearch, 300)(searchText), []);

  const onSelect = (option: AutoCompleteOption) => {
    onChange(option);
  };

  return (
    <AutoComplete
      options={options}
      style={{ width: '100%', ...style }}
      onSelect={(companyName, option) => onSelect(option as AutoCompleteOption)}
      onSearch={onSearchDebounce}
      onChange={(companyName) => {
        // This is generally custom input by the user, and not a company selection
        onChange({
          value: companyName,
        });
      }}
      placeholder="Your dream company"
      autoFocus
    />
  );
};

export default AutoCompleteCompanyInput;
