import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { UserContext } from './UserProvider';
import xhrGetJobAppBoards from '../adapters/jobAppBoards/xhrGetJobAppBoards';
import xhrCreateJobAppBoard from '../adapters/jobAppBoards/xhrCreateJobAppBoard';
import xhrDeleteJobAppBoard from '../adapters/jobAppBoards/xhrDeleteJobAppBoard';
import xhrUpdateJobAppBoard from '../adapters/jobAppBoards/xhrUpdateJobAppBoard';
import { AppOrganizationViewContext } from './AppOrganizationViewProvider';
import JobAppBoard from '../types/JobAppBoard';
import CopyBoardToOrgBody from '../types/CopyBoardToOrgBody';
import xhrCopyobAppBoardToOrg from '../adapters/jobAppBoards/xhrCopyobAppBoardToOrg';
import xhrCreateJobAppOrganizationBoard from '../adapters/jobAppBoards/xhrCreateJobAppOrgBoard';


type JobAppBoardContextData = {
  /**
   * Ideally we shouldn't be storing an active board ID, but it is currently to avoid the
   * extreme prop drilling required for job app related components that need the board ID.
   *
   * Someday we can use GraphQL instead of REST to nicely solve this issue.
   */
  activeBoardId: string|null,
  setActiveBoardId: (boardId: string|null) => void,
  boards: JobAppBoard[]|null,
  setBoards: (boards: JobAppBoard[]|null) => void
  addJobAppBoard: (name: string) => Promise<JobAppBoard>
  addJobAppOrganizationBoard: (organizationId: string) => Promise<JobAppBoard>
  deleteJobAppBoard: (id: string) => Promise<void>
  updateJobAppBoard: (id: string, updates: Partial<JobAppBoard>) => Promise<JobAppBoard>,
  getJobAppBoard: (id: string) => JobAppBoard|null
  copyToOrganization: (board: JobAppBoard, data: CopyBoardToOrgBody) => Promise<JobAppBoard>
};

const defaultFunction = () => {
  throw new Error('Job app board provider not implemented');
};

export const JobAppBoardContext = React.createContext<JobAppBoardContextData>({
  activeBoardId: null,
  setActiveBoardId: defaultFunction,
  boards: [],
  setBoards: defaultFunction,
  addJobAppBoard: defaultFunction,
  addJobAppOrganizationBoard: defaultFunction,
  deleteJobAppBoard: defaultFunction,
  updateJobAppBoard: defaultFunction,
  getJobAppBoard: defaultFunction,
  copyToOrganization: defaultFunction,
});

export const JobAppBoardProvider: React.FC = ({ children }) => {
  const [activeBoardId, setActiveBoardId] = useState<string|null>(null);
  const [boards, setBoards] = useState<JobAppBoard[]|null>(null);
  const { getAccessToken } = useContext(UserContext);
  const { organizationId } = useContext(AppOrganizationViewContext)

  useEffect(() => {
    if (organizationId === null) {
      // Currently loading
      return;
    }
    getAccessToken()
      .then(accessToken => xhrGetJobAppBoards(accessToken, organizationId))
      .then(setBoards);
  }, [organizationId, getAccessToken]);

  const addJobAppBoard: JobAppBoardContextData['addJobAppBoard'] = useCallback(async (name) => {
    const accessToken = await getAccessToken();
    const createdBoard: JobAppBoard = await xhrCreateJobAppBoard(name, accessToken);
    setBoards((curBoards) => (!curBoards ? null : [
      {
        ...createdBoard,
        jobCount: 0,
      },
      ...curBoards,
    ]));
    return createdBoard;
  }, [getAccessToken]);

  const addJobAppOrganizationBoard: JobAppBoardContextData['addJobAppOrganizationBoard'] = useCallback(async (orgId) => {
    const accessToken = await getAccessToken()
    const createdBoard = await xhrCreateJobAppOrganizationBoard(orgId, accessToken);
    setBoards((curBoards) => (!curBoards ? null : [
      {
        ...createdBoard,
        jobCount: 0,
      },
      ...curBoards,
    ]));
    return createdBoard;
  }, [getAccessToken])

  const deleteJobAppBoard: JobAppBoardContextData['deleteJobAppBoard'] = useCallback(async (id) => {
    const accessToken = await getAccessToken();
    await xhrDeleteJobAppBoard(id, accessToken);
    setBoards((curBoards) => curBoards?.filter((b) => b.id !== id) || null);
  }, [getAccessToken]);

  const updateJobAppBoard: JobAppBoardContextData['updateJobAppBoard'] = useCallback(async (id, data) => {
    const accessToken = await getAccessToken();
    const updatedBoard: JobAppBoard = await xhrUpdateJobAppBoard(id, data, accessToken);
    setBoards((curBoards) => curBoards?.map((b) => (b.id === id ? {
      ...updatedBoard,
      jobCount: b.jobCount,
    } : b)) || null);
    return updatedBoard;
  }, [getAccessToken]);

  const getJobAppBoard: JobAppBoardContextData['getJobAppBoard'] = useCallback((id) => boards
    ?.find((b) => b.id === id) || null, [boards]);

  const copyToOrganization: JobAppBoardContextData['copyToOrganization'] = async (board, data) => {
    const accessToken = await getAccessToken()
    const createdBoard: JobAppBoard = await xhrCopyobAppBoardToOrg(board.id, data, accessToken)
    if (boards) {
      setBoards([
        ...boards,
        {
          ...createdBoard,
          jobCount: board.jobCount,
        }
      ])
    }
    return createdBoard
  }

  return (
    <JobAppBoardContext.Provider value={{
      activeBoardId,
      setActiveBoardId,
      boards,
      setBoards,
      addJobAppBoard,
      addJobAppOrganizationBoard,
      deleteJobAppBoard,
      updateJobAppBoard,
      getJobAppBoard,
      copyToOrganization,
    }}
    >
      {children}
    </JobAppBoardContext.Provider>
  );
};
