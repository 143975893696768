import { useEffect, useState } from "react"
import { useOrganizationGoalFromQuery } from "../../hooks"
import { SelectedGoal } from "../../types"
import { OrganizationGoalsDesktop } from "../OrganizationGoalsDesktop"
import { OrganizationGoalsMobile } from "../OrganizationGoalsMobile"
import { createPortal } from "react-dom"

interface Props {
  sidebarContainerRef: React.RefObject<HTMLDivElement>
  renderOpenButton: (props: {onClick: () => void}) => React.ReactNode
  isOnSmallScreen: boolean
}

export const OrganizationGoals = ({
  sidebarContainerRef,
  renderOpenButton,
  isOnSmallScreen
}: Props) => {
  const [showingGoalsSidebar, setShowingGoalsSidebar] = useState(false)
  const [selectedGoal, setSelectedGoal] = useState<SelectedGoal | null>(null)
  const {goal: goalInUrl} = useOrganizationGoalFromQuery()
  const goalInUrlExists = !!goalInUrl

  const toggleSidebar = () => {
    setShowingGoalsSidebar((prev) => !prev)
  }

  useEffect(() => {
    if (goalInUrlExists) {
      const goal = goalInUrl
      
      setSelectedGoal({
        id: goal.id,
        title: goal.name,
        description: goal.checklistTaskTemplate.description,
        status: goal.status,
        dueTimestamp: goal.dueTimestamp,
        canBeUpdated: goal.canBeUpdated,
        recurringInterval: goal.checklistTaskTemplate.recurringInterval
      })

      if (isOnSmallScreen) {
        setShowingGoalsSidebar(true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalInUrlExists, isOnSmallScreen])

  if (!sidebarContainerRef.current) {
    return <>{renderOpenButton({onClick: toggleSidebar})}</>
  }

  return <>
    {renderOpenButton({onClick: toggleSidebar})}
    {createPortal(<>
      {!isOnSmallScreen && <OrganizationGoalsDesktop 
        selectedGoal={selectedGoal}
        onSelectedGoal={setSelectedGoal}
      />}
      {isOnSmallScreen && <OrganizationGoalsMobile
        isOpen={showingGoalsSidebar}
        onClose={() => setShowingGoalsSidebar(false)}
        selectedGoal={selectedGoal}
        onSelectedGoal={setSelectedGoal}
      />}
    </>, sidebarContainerRef.current)}
  </>
}
