import { stringify } from "qs";
import { getJson } from "../../../adapters/xhr";
import { OrganizationGoal } from "../types/organization-goal.type";

export interface GetOrganizationGoalInput {
  taskTemplateId: string;
  data: {
    filters: {
      organizationId: string
    };
  }
}

export interface GetOrganizationGoalOutput {
  result: OrganizationGoal
}

export const getOrganizationGoal = ({
  data,
  taskTemplateId
}: GetOrganizationGoalInput, accessToken: string): Promise<GetOrganizationGoalOutput> => {
  const filtersQuery = stringify(data)
  return getJson(
    `/user-checklist-tasks/${taskTemplateId}?${filtersQuery}`,
    accessToken
  )
}
