import styled from "styled-components";
import themeColors from "../../../../constants/themeColors";

export const NotificationsPopoverContainer = styled.div`
  min-height: 300px;
  overflow: auto;
`

export const NotificationsPopoverTitle = styled.h1`
  color: ${themeColors.background.dark};
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
`
