import React, { useCallback, useContext } from 'react';
import { UserContext } from './UserProvider';
import xhrGetLocationsAutoComplete from '../adapters/locations/xhrGetLocationsAutocomplete';
import LocationAutocomplete from '../types/LocationAutocomplete';

type LocationsContextData = {
  getLocationsAutocomplete: (query: string) => Promise<Array<LocationAutocomplete>>
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const LocationsContext = React.createContext<LocationsContextData>({
  getLocationsAutocomplete: defaultFunction,
});

export const LocationsProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useContext(UserContext)

  const getLocationsAutocomplete: LocationsContextData['getLocationsAutocomplete'] = useCallback(async (query) => {
    const accessToken = await getAccessToken();
    const locations = await xhrGetLocationsAutoComplete(accessToken, query)

    return locations;
  }, [getAccessToken])

  return (
    <LocationsContext.Provider value={{
      getLocationsAutocomplete,
    }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
