import { useMutation, useQueryClient } from "react-query";
import useAuthZero from "../../../hooks/useAuthZero";
import { updateOrganizationGoal, UpdateOrganizationGoalInput } from "../api";

export const useUpdateOrganizationGoal = () => {
  const { getAccessToken } = useAuthZero();
  const queryClient = useQueryClient()

  const { status, mutateAsync } = useMutation<
    void,
    Error,
    UpdateOrganizationGoalInput
  >(async (details) => {
    const accessToken = await getAccessToken()();

    return updateOrganizationGoal(details, accessToken);
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ['organization-goals']
      })
    }
  });

  return {
    status,
    mutateAsync,
  };
};
