import { useLocation } from "@reach/router"
import { useContext, useEffect, useState } from "react"
import { AppOrganizationViewContext } from "../../../contexts/AppOrganizationViewProvider"
import { OrganizationGoal } from "../types"
import { useOrganizationGoal } from "./useOrganizationGoal"

export const useOrganizationGoalFromQuery = () => {
  const { selectedOrganization } = useContext(AppOrganizationViewContext)
  const location = useLocation()
  const goalIdInSearch = new URLSearchParams(location.search).get('goal')
  const [selectedGoal, setSelectedGoal] = useState<OrganizationGoal | null>(null)

  const {data: goalResultInUrl} = useOrganizationGoal(goalIdInSearch ? {
    taskTemplateId: goalIdInSearch,
    input: {
      taskTemplateId: goalIdInSearch,
      data: {
        filters: {
          organizationId: selectedOrganization?.id as string,
        },
      }
    }
  } : {
    taskTemplateId: null,
  })
  const goalInUrlExists = !!goalResultInUrl

  
  useEffect(() => {
    if (goalInUrlExists && goalResultInUrl) {
      const goal = goalResultInUrl.result
      
      setSelectedGoal(goal)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalInUrlExists])

  return {goal: selectedGoal}
}