import { Button, Divider } from "antd";
import { useContext, useState } from "react";
import ContactForm from "./ContactForm/ContactForm";
import ContactCard from "./ContactCard/ContactCard";
import { ContactsAreaAddSection, ContactsAreaCards, ContactsAreaHeading } from "./ContactsArea.styles";
import Contact from "../../../../types/Contact";
import ContactSearch from "./ContactSearch/ContactSearch";
import { ContactsContext } from "../../../../contexts/ContactsProvider";
import JobApp from "../../../../types/JobApp";

interface Props {
  jobApp: JobApp
  onAddedContact: (contact: Contact) => void
  onRemovedContact: (contact: Contact) => void
  onUpdatedContact: (contact: Contact) => void
}

const ContactsArea: React.FC<Props> = ({
  jobApp,
  onAddedContact,
  onRemovedContact,
  onUpdatedContact
}) => {
  const { createContactForJobApp } = useContext(ContactsContext)
  const [addingNew, setAddingNew] = useState(false);

  const useContacts = jobApp.contacts.sort((a, b) => {
    const aTime = new Date(a.createdAt).getTime()
    const bTime = new Date(b.createdAt).getTime()
    return bTime - aTime
  })

  const onClickAddNew = () => {
    setAddingNew(true);
  }

  const onCancelAddContact = () => {
    setAddingNew(false);
  }

  const onContactAdded = async (contact: Partial<Contact>) => {
    const created = await createContactForJobApp(jobApp.id, contact)
    onAddedContact(created);
    setAddingNew(false);
  }

  const onClickRemoveContact = (contact: Contact) => {
    onRemovedContact(contact);
  }

  return (<>
    {!addingNew && <ContactsAreaAddSection>
      <ContactSearch jobApp={jobApp} onAddedContact={onContactAdded} />
      <Divider plain>
        or
      </Divider>
      <Button type="primary" onClick={onClickAddNew}>
        Create a new contact
      </Button>
    </ContactsAreaAddSection>}
    {addingNew && <ContactForm 
      onSubmit={onContactAdded}
      onCancel={onCancelAddContact}
    />}
    {useContacts?.length > 0 && <ContactsAreaHeading>
      Current Contacts
    </ContactsAreaHeading>}
    <ContactsAreaCards>
      {useContacts.map(contact => <ContactCard
        key={contact.id}
        jobApp={jobApp}
        contact={contact}
        onUpdated={onUpdatedContact}
        onDeleted={onClickRemoveContact}
      />)}
    </ContactsAreaCards>
  </>
  )
}

export default ContactsArea;
