import React, { useContext, useEffect, useState } from 'react';
import {
  Breadcrumb, Typography,
} from 'antd';
import { Link } from '@reach/router';
import pages from '../../constants/pages';
import { JobAppBoardContext } from '../../contexts/JobAppBoardProvider';
import { AppOrganizationViewContext } from '../../contexts/AppOrganizationViewProvider';

const BoardSelector: React.FC = () => {
  const { boards, getJobAppBoard, activeBoardId: boardId } = useContext(JobAppBoardContext);
  const { selectedOrganization } = useContext(AppOrganizationViewContext);
  const [boardName, setBoardName] = useState('');

  useEffect(() => {
    if (boardId) {
      setBoardName(getJobAppBoard(boardId)?.name || '');
    } else {
      setBoardName('');
    }
  }, [boardId, getJobAppBoard]);


  const boardsDropdownMenuItems = (boards || [])
    // Show the boards based on the which view they're in
    .filter(board => !selectedOrganization?.id ? !board.organizationId : board.organizationId === selectedOrganization.id)
    // Don't show the board they're currently on
    .filter(board => board.id !== boardId)
    .map((board) => ({
      key: board.id,
      label: <Link to={`${pages.BOARD}/${board.id}`}>{board.name}</Link>
    }))
  

  if (selectedOrganization) {
    return <Breadcrumb separator='>' style={{ fontSize: '1rem' }}>
      <Breadcrumb.Item>
        <Typography.Text strong style={{
          fontSize: '1rem'
        }}>
          {selectedOrganization.name}
        </Typography.Text>
      </Breadcrumb.Item>
    </Breadcrumb>
  }

  return (
    <Breadcrumb separator=">" style={{
      fontSize: '1rem'
    }}>
      <Breadcrumb.Item>
        <Link to={pages.BOARD}>
          <Typography.Text>
        Boards
          </Typography.Text>
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item 
        dropdownProps={{
          trigger: ['hover'],
        }}
        {...boardsDropdownMenuItems.length && {
          menu: {
            items: boardsDropdownMenuItems,
          }
        }}
      >
        <Typography.Text>
          {boardName}
        </Typography.Text>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default BoardSelector;
