import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import {
  Button, Divider, Popover, Space,
} from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import pages from '../../../constants/pages';
import User from '../../../types/User';

const DividerStyled = styled(Divider)`
  margin: 0.5rem 0;
`;

const Username = styled.span`
  font-weight: 600;
  font-size: 0.95rem;
`;

const ButtonStyled = styled(Button)`
  display: block;
  padding: 0;
  font-weight: 500;
  font-size: 0.9rem;
`;

interface UserPopoverProps {
  user: User|null
  onLogout: () => void
}

const UserPopover: React.FC<UserPopoverProps> = ({
  user,
  onLogout,
}) => {
  const [disableLogout, setDisableLogout] = useState(false);

  const onClickSettings = () => {
    navigate(pages.SETTINGS_ACCOUNT);
  };

  const onClickLogout = () => {
    setDisableLogout(true);
    onLogout();
  };

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      overlayStyle={{
        width: '200px',
      }}
      content={(
        <div>
          <Space style={{
            wordBreak: 'break-all'
          }}>
            <Username>{user?.name}</Username>
          </Space>
          <DividerStyled />
          <ButtonStyled
            type="link"
            onClick={onClickSettings}
          >
            Settings
          </ButtonStyled>
          <ButtonStyled
            type="link"
            onClick={onClickLogout}
            disabled={disableLogout}
          >
            Sign out
          </ButtonStyled>
        </div>
      )}
    >
      <Button
        shape="circle"
        type="text"
        icon={<FontAwesomeIcon icon={faUser} />}
        size="large"
      />
    </Popover>
  );
};

export default UserPopover;
