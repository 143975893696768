import React, { useState } from 'react';
import {
  Button, Divider, Tabs,
} from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import EditJobAppDocuments from './EditJobAppDocuments';
import JobAppStatusDropdown from './JobAppStatusDropdown';
import JobAppInput from './JobAppInput';
import JobAppColorSelection from './JobAppColorSelection';
import JobAppAppliedDatePicker from './JobAppAppliedDatePicker';
import JobApp from '../../../types/JobApp';
import themeColors from '../../../constants/themeColors';
import JobAppAvatar from '../JobAppAvatar';
import InterviewSection from './InterviewSection/InterviewSection';
import { DATE_FORMAT_WITH_TIME } from '../../../constants/dateTimeFormats';
import JobAppEditCompany from './JobAppEditCompany';
import JobAppRichTextEditor from './JobAppRichTextEditor';
import NotesList from './NotesList/NotesList';
import ContactsArea from './ContactsArea/ContactsArea';
import Contact from '../../../types/Contact';
import JobAppNote from '../../../types/JobAppNote';
import JobAppEditLocation from './JobAppEditLocation';
import JobAppEditTitle from './JobAppEditTitle';

const ModalSettings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  > span {
    color: ${themeColors.background.gray};
    font-size: 0.9em;
  }
`;

const ModalLeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* padding: 24px; */
`;

const ModalLeftPanelHeader = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 12px 12px 0px 12px;
   > div:first-child {
    flex: 1;
    margin-right: 1rem;
   }
`;

const ModalRightPanel = styled.div`
  max-width: 250px; 
  /* background: ${themeColors.background.offWhite}; */
  /** Top and right margins used to override modal body's padding */
  /* margin: -12px -12px 0 0; */
  padding: 12px 12px 12px 12px;
  overflow: auto;
  border-left: solid 1px ${themeColors.background.offWhite};
`;

const Field = styled.div`
  padding-bottom: 24px;
`;

const BoxShadow = styled.div`
  box-shadow: 0 1px 5px rgba(71, 89, 107, 0.1);
`;

const Label = styled.span`
  color: ${themeColors.background.dark};
  font-weight: 500;
  /* font-size: 1rem; */
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    overflow: auto;
    border-left: none;
  }
  overflow: hidden;
  flex-grow: 1;
  padding: 0 24px 24px 0;
  .ant-tabs-nav .ant-tabs-tab {
    padding-left: 24px;
  }
`;

interface EditJobAppModalDesktopContentProps {
  jobApp?: JobApp;
  onAddedContact: (contact: Contact) => void;
  onRemovedContact: (contact: Contact) => void;
  onAddedNote: (note: JobAppNote) => void
  onRemovedNote: (note: JobAppNote) => void;
  onUpdatedNote: (note: JobAppNote) => void;
  onUpdatedJobApp: (jobApp: JobApp) => void;
  onUpdatedContact: (contact: Contact) => void;
}

const EditJobAppModalDesktopContent: React.FC<EditJobAppModalDesktopContentProps> = ({
  jobApp,
  onAddedContact,
  onRemovedContact,
  onAddedNote,
  onRemovedNote,
  onUpdatedNote,
  onUpdatedJobApp,
  onUpdatedContact,
}) => {
  const [newCompanySelected, setNewCompanySelected] = useState<{
    value: string,
    logoUrl?: string
  }|null>(null);

  if (!jobApp) {
    return <div />;
  }

  const {
    url,
  } = jobApp;

  let jobAppUrl = url || '';
  if (jobAppUrl && !jobAppUrl.match(/^https?:\/\//i)) {
    jobAppUrl = `https://${jobAppUrl}`;
  }
  const jobAppUrlLinkButton = (
    <Button
      icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
      type="link"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 10%)',
        borderStyle: 'none',
        margin: '4px',
        height: '24px',
      }}
      href={jobApp.url || ''}
      target="_blank"
      rel="noreferrer noopener"
      disabled={!jobApp.url}
    />
  );

  const updatedAtText = `Updated at ${dayjs(jobApp.updatedAt).format(DATE_FORMAT_WITH_TIME)}`;

  const desktopView = (
    <>
      <ModalLeftPanel>
        {/* <Header> */}
        <Field>
          <ModalLeftPanelHeader>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <JobAppColorSelection jobApp={jobApp} />
                <JobAppEditCompany jobApp={jobApp} onSelected={setNewCompanySelected} />
              </div>
              <JobAppEditTitle jobApp={jobApp} />
              <JobAppEditLocation jobApp={jobApp} />
            </div>
            <JobAppAvatar
              size={64}
              style={{
                marginRight: '1rem',
              }}
              jobApp={{
                ...jobApp,
                companyLogoUrl: newCompanySelected?.logoUrl || jobApp.companyLogoUrl,
              }}
            />
          </ModalLeftPanelHeader>
          <Divider style={{marginTop: '15px', marginBottom: '0px'}} />
        </Field>
        <StyledTabs
          tabPosition='left'
          defaultActiveKey="description"
          /**
           * For any rich text editors, the toolbar can only properly be mounted once, otherwise it
           * will show blank buttons. Here, we unmount the tab contents when we switch tabs so the
           * toolbar can be mounted in other tabs if needed.
           */
          destroyInactiveTabPane
          items={[{
            key: 'description',
            label: 'Description',
            children: <Field>
              <Label aria-label="Description" />
              <JobAppRichTextEditor 
                jobApp={jobApp}
                jobAppField='description' 
                onUpdatedJobApp={onUpdatedJobApp}
              />
            </Field>
          }, {
            key: 'interviews',
            label: 'Interviews',
            children: <InterviewSection jobApp={jobApp} />
          }, {
            key: 'contacts',
            label: 'Contacts',
            children: <ContactsArea 
              onAddedContact={onAddedContact}
              jobApp={jobApp} 
              onRemovedContact={onRemovedContact}
              onUpdatedContact={onUpdatedContact}
            />
          }, {
            key: 'notes',
            label: 'Notes',
            children: <NotesList 
              jobApp={jobApp}
              onAddedNote={onAddedNote} 
              onRemovedNote={onRemovedNote}
              onUpdatedNote={onUpdatedNote}
            />
          }]}
        >
        </StyledTabs>
      </ModalLeftPanel>
      {/* <Divider type="vertical" /> */}
      <ModalRightPanel>
        <ModalSettings>
          <span>
            {updatedAtText}
          </span>
        </ModalSettings>
        <Field className="job-status-dropdown-header">
          <Label>Status</Label>
          <BoxShadow>
            <JobAppStatusDropdown jobApp={jobApp} />
          </BoxShadow>
        </Field>
        <Field>
          <Label>Date Created</Label>
          <BoxShadow>
            <JobAppAppliedDatePicker jobApp={jobApp} style={{ width: '100%' }} />
          </BoxShadow>
        </Field>
        <Field>
          <Label>Salary</Label>
          <BoxShadow>
            <JobAppInput property="salary" jobApp={jobApp} />
          </BoxShadow>
        </Field>
        <Field>
          <Label as="label" htmlFor="edit-jobapp-url">URL</Label>
          <BoxShadow style={{ position: 'relative' }}>
            <JobAppInput property="url" jobApp={jobApp} />
            {jobAppUrlLinkButton}
          </BoxShadow>
        </Field>
        <Field>
          <EditJobAppDocuments jobApp={jobApp} />
        </Field>
      </ModalRightPanel>
    </>
  );

  return desktopView;
};

export default EditJobAppModalDesktopContent;
