import { getJson } from '../xhr';

export default function xhrSearchContacts(
  search: string,
  accessToken: string,
) {
  const encodedSearch = encodeURIComponent(search);
  return getJson(
    `/contacts?search=${encodedSearch}`,
    accessToken,
  );
}
