import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { DropResult } from 'react-beautiful-dnd';
import xhrGetBoardJobApps from '../adapters/jobApps/xhrGetBoardJobApps';
import xhrUpdateJobApp from '../adapters/jobApps/xhrUpdateJobApp';
import JobApp from '../types/JobApp';
import { UserContext } from './UserProvider';
import xhrDeleteJobApp from '../adapters/jobApps/xhrDeleteJobApp';
import xhrAttachJobAppDocument from '../adapters/jobApps/xhrAttachJobAppDocument';
import xhrDetachJobAppDocument from '../adapters/jobApps/xhrDetachJobAppDocument';
import xhrCreateJobApp from '../adapters/jobApps/xhrCreateJobApp';
import xhrSortJobApps from '../adapters/jobApps/xhrSortJobApps';
import xhrGetJobApps from '../adapters/jobApps/xhrGetJobApps';
import JobAppUpdate from '../types/JobAppUpdate';

type JobAppMovedDetails = {
  destColumnId: string,
  destJobAppIndex?: number,
  sourceColumnId: string,
  sourceJobAppIndex?: number,
  jobAppId: string;
  boardId: string;
};

type JobAppContextData = {
  jobApps: JobApp[]|null,
  setJobApps: (jobApps: JobApp[]|null) => void,
  editingJobAppId: string|null,
  setEditingJobAppId: (id: string|null) => void,
  editingJobApp: JobApp|null,
  createJobApp: (boardId: string, columnId: string, details: any) => Promise<JobApp>
  searchJobApps: (searchTerm: string) => ReturnType<typeof xhrGetJobApps>,
  getBoardJobApps: (boardId: string, columnId: string) => Promise<JobApp[]>,
  updateJobApp: (
    jobAppId: string,
    updates: JobAppUpdate
  ) => Promise<JobApp>
  deleteJobApp: (
    boardId: string,
    columnId: string,
    jobAppId: string
  ) => Promise<void>
  attachDocumentJobApp: (
    boardId: string,
    columnId: string,
    jobAppId: string,
    documentId: string,
  ) => Promise<void>
  detachJobAppDocument: (
    boardId: string,
    columnId: string,
    jobAppId: string,
    documentId: string,
  ) => Promise<void>
  sortJobAppColumn: (
    boardId: string,
    columnId: string,
    sortOptions: {
      by: 'title'|'company'|'appliedAt',
      descending: boolean,
    }
  ) => Promise<void>
  moveJobApp: (board: string, result: DropResult) => Promise<void>;
  updateJobAppVisualPosition: (details: JobAppMovedDetails) => void
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const JobAppContext = React.createContext<JobAppContextData>({
  jobApps: null,
  setJobApps: defaultFunction,
  editingJobAppId: null,
  editingJobApp: null,
  createJobApp: defaultFunction,
  searchJobApps: defaultFunction,
  getBoardJobApps: defaultFunction,
  setEditingJobAppId: defaultFunction,
  updateJobApp: defaultFunction,
  deleteJobApp: defaultFunction,
  attachDocumentJobApp: defaultFunction,
  detachJobAppDocument: defaultFunction,
  sortJobAppColumn: defaultFunction,
  moveJobApp: defaultFunction,
  updateJobAppVisualPosition: defaultFunction,
});

export const JobAppProvider: React.FC = ({ children }) => {
  const [jobApps, setJobApps] = useState<JobApp[]|null>(null);
  const [editingJobAppId, setEditingJobAppId] = useState<string|null>(null);
  const { getAccessToken } = useContext(UserContext);
  const editingJobApp = useMemo(() => jobApps
    ?.find((app) => app.id === editingJobAppId) || null, [editingJobAppId, jobApps]);

  const searchJobApps: JobAppContextData['searchJobApps'] = useCallback(async (searchTerm) => {
    const accessToken = await getAccessToken();
    const response = await xhrGetJobApps(searchTerm, accessToken);
    return response;
  }, [getAccessToken])

  const getBoardJobApps: JobAppContextData['getBoardJobApps'] = useCallback(async (boardId, columnId) => {
    const accessToken = await getAccessToken();
    return xhrGetBoardJobApps(boardId, columnId, accessToken);
  }, [getAccessToken]);

  const updateJobApp: JobAppContextData['updateJobApp'] = useCallback(async (jobAppId, updates) => {
    const accessToken = await getAccessToken();
    const updated = await xhrUpdateJobApp(jobAppId, {
      ...updates,
    }, accessToken);
    setJobApps((curJobApps) => (!curJobApps ? null : curJobApps
      .map((jobApp) => (jobApp.id === jobAppId ? updated : jobApp))));
    return updated;
  }, [getAccessToken]);

  const deleteJobApp: JobAppContextData['deleteJobApp'] = useCallback(async (boardId, columnId, jobAppId) => {
    const accessToken = await getAccessToken();
    await xhrDeleteJobApp(boardId, columnId, jobAppId, accessToken);
    setJobApps((curJobApps) => (!curJobApps
      ? null
      : curJobApps.filter((jobApp) => jobApp.id !== jobAppId)));
  }, [getAccessToken]);

  const attachDocumentJobApp: JobAppContextData['attachDocumentJobApp'] = useCallback(async (
    boardId,
    columnId,
    jobAppId,
    documentId,
  ) => {
    const accessToken = await getAccessToken();
    await xhrAttachJobAppDocument(boardId, columnId, jobAppId, documentId, accessToken);
    setJobApps((curJobApps) => (!curJobApps ? null : curJobApps.map((jobApp) => {
      if (jobApp.id === jobAppId) {
        return {
          ...jobApp,
          documentIds: [...jobApp.documentIds, documentId],
        };
      }
      return jobApp;
    })));
  }, [getAccessToken]);

  const detachJobAppDocument: JobAppContextData['detachJobAppDocument'] = useCallback(async (
    boardId,
    columnId,
    jobAppId,
    documentId,
  ) => {
    const accessToken = await getAccessToken();
    await xhrDetachJobAppDocument(boardId, columnId, jobAppId, documentId, accessToken);
    setJobApps((curJobApps) => (!curJobApps ? null : curJobApps.map((jobApp) => {
      if (jobApp.id === jobAppId) {
        return {
          ...jobApp,
          documentIds: jobApp.documentIds.filter((docId) => docId !== documentId),
        };
      }
      return jobApp;
    })));
  }, [getAccessToken]);

  const createJobApp: JobAppContextData['createJobApp'] = useCallback(async (boardId, columnId, details) => {
    const accessToken = await getAccessToken();
    const created = await xhrCreateJobApp(boardId, columnId, details, accessToken);
    setJobApps((curJobApps) => (!curJobApps ? null : [
      created,
      ...curJobApps,
    ]));
    return created;
  }, [getAccessToken]);

  const sortJobAppColumn: JobAppContextData['sortJobAppColumn'] = useCallback(async (boardId, columnId, sortOptions) => {
    const accessToken = await getAccessToken();
    const sortedJobAppIds: string[] = await xhrSortJobApps(
      boardId,
      columnId,
      sortOptions,
      accessToken,
    );
    setJobApps((curJobApps) => {
      if (!curJobApps) {
        return curJobApps;
      }
      const sortedJobApps = sortedJobAppIds.map((id) => curJobApps
        .find((curApp) => curApp.id === id))
        .filter((app) => app);
      return curJobApps
        .filter((jobApp) => jobApp.columnId !== columnId)
        .concat(sortedJobApps as JobApp[]);
    });
  }, [getAccessToken]);

  const updateJobAppVisualPosition = useCallback((details: JobAppMovedDetails) => {
    const {
      sourceColumnId, sourceJobAppIndex, destColumnId, destJobAppIndex, jobAppId,
    } = details;
    const sourceJobApp = jobApps?.find((j) => j.id === jobAppId);
    if (!sourceJobApp || !jobApps) {
      return;
    }
    const isMovedWithinSameColumn = destColumnId === sourceColumnId;
    const sourceJobApps = jobApps.filter((jobApp) => jobApp.columnId === sourceColumnId);
    const destJobApps = isMovedWithinSameColumn
      ? sourceJobApps
      : jobApps.filter((jobApp) => jobApp.columnId === destColumnId);
    const removeIndex = sourceJobAppIndex ?? sourceJobApps
      .findIndex((app) => app.id === jobAppId);
    sourceJobApps.splice(removeIndex, 1);
    // Move all positions >= removeindex by -1 to fill up the empty position from splice
    for (let i = removeIndex; i < sourceJobApps.length; i += 1) {
      sourceJobApps[i].position -= 1;
    }
    const addIndex = destJobAppIndex ?? destJobApps.length;
    // Move all positions >= addIndex by +1 to make room for the moved job app
    for (let i = addIndex; i < destJobApps.length; i += 1) {
      destJobApps[i].position += 1;
    }
    destJobApps.splice(addIndex, 0, {
      ...sourceJobApp,
      columnId: destColumnId,
      position: addIndex,
    });

    // Remove all the job apps of the columns under operations, and add them back in order
    const updatedJobApps = jobApps
      .filter(({ columnId }) => columnId !== sourceColumnId && columnId !== destColumnId)
      .concat(sourceJobApps);

    if (!isMovedWithinSameColumn) {
      setJobApps(updatedJobApps.concat(destJobApps));
    } else {
      setJobApps(updatedJobApps);
    }
  }, [jobApps]);

  const moveJobApp = useCallback(async (boardId: string, result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      throw new Error('Destination is undefined');
    }
    const payload = {
      destColumnId: destination.droppableId,
      destJobAppIndex: destination.index,
      sourceColumnId: source.droppableId,
      sourceJobAppIndex: source.index,
      jobAppId: draggableId,
      boardId,
    };
    updateJobAppVisualPosition(payload);
    try {
      await updateJobApp(draggableId, {
        columnId: payload.destColumnId,
        position: payload.destJobAppIndex,
      });
    } catch (err) {
      // Reverse the movement if failed
      updateJobAppVisualPosition({
        ...payload,
        destColumnId: payload.sourceColumnId,
        destJobAppIndex: payload.sourceJobAppIndex,
        sourceColumnId: payload.destColumnId,
        sourceJobAppIndex: payload.destJobAppIndex,
      });
      throw err;
    }
  }, [updateJobApp, updateJobAppVisualPosition]);

  return (
    <JobAppContext.Provider value={{
      jobApps,
      setJobApps,
      editingJobAppId,
      editingJobApp,
      setEditingJobAppId,
      createJobApp,
      searchJobApps,
      getBoardJobApps,
      updateJobApp,
      deleteJobApp,
      attachDocumentJobApp,
      detachJobAppDocument,
      sortJobAppColumn,
      moveJobApp,
      updateJobAppVisualPosition,
    }}
    >
      {children}
    </JobAppContext.Provider>
  );
};
