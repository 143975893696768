import React, {
  useState, useMemo, useRef, useContext,
} from 'react';
import {
  Select, Button, List, Upload,
} from 'antd';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUpload } from '@fortawesome/free-solid-svg-icons';
import JobApp from '../../../types/JobApp';
import DocumentListItem from '../DocumentListItem';
import notifyError from '../../../util/notifyError';
import { DocumentContext, Document } from '../../../contexts/DocumentProvider';
import { JobAppContext } from '../../../contexts/JobAppProvider';
import { JobAppBoardContext } from '../../../contexts/JobAppBoardProvider';
import themeColors from '../../../constants/themeColors';

const Wrapper = styled.div`
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const flash = keyframes`
  from {
    color: initial;
  }
  25% {
    color: green;
  }
  75% {
    color: green;
  }
  to {
    color: initial;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const LabelContainer = styled.div`
  color: ${themeColors.background.dark};
  font-weight: 500;
  width: 100%;
  position: relative;
  button {
    border-radius: 5px;
    position: absolute !important;
    right: 0;
    top: -5px;
  }
`;

const NoDocumentsText = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: lightgray;
`;

const ListScroll = styled.div`
  margin-top: 5px;
  max-height: 130px;
  overflow: auto;
  .flash {
    animation: ${flash} .5s linear 0s 1;
  }
`;

const StyledUpload = styled(Upload)`
  display: block;
  .ant-upload-select {
    display: block;
  }
`

const UploadDocumentButton = styled(Button)`
  color: #5f5f5f;
`;

const DocName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

function getAvailableAdditions(jobApp: JobApp, documents: Document[]) {
  const jobAppDocIds = new Set(jobApp.documentIds);
  return documents.filter((doc) => !jobAppDocIds.has(doc.id));
}

interface EditJobAppDocumentsProps {
  jobApp: JobApp;
}

const EditJobAppDocuments: React.FC<EditJobAppDocumentsProps> = ({ jobApp }) => {
  const [adding, setAdding] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [flashDocId, setFlashDocId] = useState('');
  const [removingDocIds, setRemovingDocIds] = useState<string[]>([]);
  const { activeBoardId } = useContext(JobAppBoardContext);
  const { documents, createDocument } = useContext(DocumentContext);
  const { attachDocumentJobApp, detachJobAppDocument } = useContext(JobAppContext);
  const docs = useMemo(() => documents || [], [documents]);
  const selectOptions = getAvailableAdditions(jobApp, docs);

  const docsById = useMemo(() => {
    const map = new Map<string, Document>();
    for (let i = 0; i < docs.length; i += 1) {
      map.set(docs[i].id, docs[i]);
    }
    return map;
  }, [docs]);
  const listRef = useRef<HTMLDivElement>(null);

  function setAttentionOnNewDoc(docId: string) {
    if (listRef.current) {
      listRef.current.scrollBy({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    // Wait for scroll to finish
    setTimeout(() => {
      // Flash
      setFlashDocId(docId);
      // Wait for flash to finish
      setTimeout(() => {
        setFlashDocId('');
      }, 500);
    }, 500);
  }

  async function removeDocFromJobApp(documentId: string) {
    if (!activeBoardId) {
      throw new Error('No active board ID for remove doc from job');
    }
    try {
      const original = [...removingDocIds];
      setRemovingDocIds(original.concat([documentId]));
      await detachJobAppDocument(activeBoardId, jobApp.columnId, jobApp.id, documentId);
      setRemovingDocIds(original);
    } catch (err) {
      notifyError('Unable to remove document from job app', (err as Error).message);
    }
  }

  async function addDocToJobApp(documentId: string) {
    if (!activeBoardId) {
      throw new Error('No active board ID for add doc to job');
    }
    try {
      setAdding(true);
      await attachDocumentJobApp(activeBoardId, jobApp.columnId, jobApp.id, documentId);
      setAdding(false);
      setAttentionOnNewDoc(documentId);
      return true;
    } catch (err) {
      setAdding(false);
      notifyError('Unable to add document to job app', (err as Error).message);
      return false;
    }
  }

  async function uploadFile(file: File) {
    setUploading(true);
    try {
      const addedDoc = await createDocument(file.name, file);
      const success = await addDocToJobApp(addedDoc.id);
      if (success) {
        setAttentionOnNewDoc(addedDoc.id);
      }
    } catch (err) {
      notifyError('Unable to upload file', (err as Error).message);
    }
    setUploading(false);
  }

  return (
    <Wrapper>
      <Header>
        <LabelContainer>
          <span>Documents</span>

        </LabelContainer>
        {/* <Button>Upload</Button> */}
      </Header>
      <StyledUpload
        name="upload-new-document"
        className='test'
        showUploadList={false}
        customRequest={({ file }) => uploadFile(file as File)}
        accept=".pdf,application/pdf"
        style={{
          width: '100%',
          display: 'block',
        }}
      >
        <UploadDocumentButton
          aria-label="Upload and attach new document"
          size="small"
          loading={uploading}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '0.25rem' }}
          icon={<FontAwesomeIcon icon={faUpload} style={{ marginRight: '0.5rem' }} size="sm" />}
        >
        Upload
        </UploadDocumentButton>
      </StyledUpload>
      <StyledSelect
        style={{ boxShadow: '0 1px 5px rgba(71, 89, 107, 0.1)' }}
        aria-label="Find and add a document"
        placeholder="Find and add a document"
        showSearch
        suffixIcon={<FontAwesomeIcon icon={faChevronDown} />}
        id="find-jobapp-document"
        filterOption={(input, option) => {
          if (!option) {
            return false;
          }
          return (option.children as string).toLowerCase().includes(input.toLowerCase());
        }}
        value={undefined}
        loading={adding}
        disabled={adding}
        onSelect={(value) => {
          addDocToJobApp(value as string);
        }}
      >
        {selectOptions.map((doc) => (
          <Select.Option key={doc.id} value={doc.id}>{doc.name}</Select.Option>
        ))}
      </StyledSelect>
      {jobApp.documentIds.length === 0
        ? <NoDocumentsText>No documents are attached to this application</NoDocumentsText>
        : (
          <ListScroll ref={listRef}>
            <List
              size="small"
              dataSource={jobApp.documentIds}
              renderItem={(docId) => {
                const doc = docsById.get(docId);
                if (!doc) {
                  return null;
                }
                return (
                  <DocumentListItem
                    key={doc.id}
                    className={flashDocId === doc.id ? 'flash' : ''}
                    onDelete={() => removeDocFromJobApp(doc.id)}
                    disabledDelete={removingDocIds.includes(doc.id)}
                    doc={doc}
                  >
                    <DocName>
                      {doc.name}
                    </DocName>
                  </DocumentListItem>
                );
              }}
            />
          </ListScroll>
        )}
    </Wrapper>
  );
};

export default EditJobAppDocuments;
