import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import GenericError from '../components/Error';

function CrashError({ eventId }: InferProps<typeof CrashError.propTypes>) {
  return (
    <GenericError
      title="Sorry, something went wrong."
      description={(
        <span>
          Our team has been notified and is working on it.
          If you require further support, please don’t hesitate to contact us at
          {' '}
          <a href="mailto:support@stipplo.com">support@stipplo.com </a>
          with the details below.
          <br />
          <br />
          <span style={{ color: 'gray', fontSize: '1rem' }}>
            Technical Details:
            {' '}
            {eventId}
          </span>
        </span>
      )}
    />
  );
}

CrashError.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default CrashError;
