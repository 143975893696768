import * as Sentry from '@sentry/react';

export const STIPPLO_ORGANIZATION_HEADER = 'stipplo-organization'

export class StatusCodeError extends Error {
  code: number;

  requestUrl: string;

  response: Response;

  constructor(code: number, requestUrl: string, response: Response) {
    super(`An internal error happened (code ${code}). Please try again later, or contact support@stipplo.com for assistance.`);
    this.code = code;
    this.requestUrl = requestUrl;
    this.response = response;
  }
}

export const GENERIC_NETWORK_ERROR = 'Internal error. Please try again later, or contact support@stipplo.com for assistance.';

// Usually http://localhost:8081/api
export let BASE_API_URI = ''

const NODE_ENV = process.env.NODE_ENV;

if (NODE_ENV === 'production') {
  BASE_API_URI = process.env.REACT_APP_API_PRODUCTION_URL as string;
} else if (NODE_ENV === 'test') {
  BASE_API_URI = ''
} else if (NODE_ENV === 'development') {
  BASE_API_URI = process.env.REACT_APP_API_DEVELOPMENT_URL as string;
} 
 
export const get = async (
  endpoint: string,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const url = `${BASE_API_URI}${endpoint}`

    const res = await fetch(url, {
      method: 'GET',
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        ...options?.headers,
      },
    });

    return res;
  } catch (err) {
    Sentry.captureException(err);
    throw new Error(GENERIC_NETWORK_ERROR);
  }
};

export const getFile = async (
  endpoint: string,
  authToken: string,
  options: RequestInit = {},
) => fetch(`${BASE_API_URI}${endpoint}`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
  ...options,
});

export const post = async (
  endpoint: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(`${BASE_API_URI}${endpoint}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(body),
      ...options,
    });
    return res;
  } catch (err) {
    Sentry.captureException(err);
    throw new Error(GENERIC_NETWORK_ERROR);
  }
};

export const patch = async (
  endpoint: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(`${BASE_API_URI}${endpoint}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(body),
      ...options,
    });
    
    if (!res.ok) {
      throw new StatusCodeError(res.status, endpoint, res);
    }

    return res;
  } catch (err) {
    Sentry.captureException(err);
  
    if (!(err instanceof StatusCodeError)) {
      throw new Error(GENERIC_NETWORK_ERROR);
    } else {
      throw err
    }
  }
};

export const deleteFor = async (
  endpoint: string,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(`${BASE_API_URI}${endpoint}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      ...options,
    });
    if (!res.ok) {
      throw new StatusCodeError(res.status, endpoint, res);
    }
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const putFor = async (
  endpoint: string,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(`${BASE_API_URI}${endpoint}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      ...options,
    });
    if (!res.ok) {
      throw new StatusCodeError(res.status, endpoint, res);
    }
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const postForJson = async (
  endpoint: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  let res;
  try {
    res = await post(`${endpoint}`, body, authToken, options);
    if (!res.ok) {
      throw new StatusCodeError(res.status, endpoint, res);
    }
    return await res.json();
  } catch (err) {
    if (err instanceof StatusCodeError && err.code === 403) {
      const json = await res?.json();
      throw new Error(json.message);
    } else {
      Sentry.captureException(err);
      throw err;
    }
  }
};

export const postFormForJson = async (
  endpoint: string,
  form: FormData,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(`${BASE_API_URI}${endpoint}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: form,
      ...options,
    });
    if (!res.ok) {
      throw new StatusCodeError(res.status, endpoint, res);
    }
    return await res.json();
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const patchForJson = async (
  endpoint: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await patch(`${endpoint}`, body, authToken, options);
    if (!res.ok) {
      throw new StatusCodeError(res.status, endpoint, res);
    }
    const json = await res.json();
    return json;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const getJson = async (endpoint: string, authToken: string, options: RequestInit = {}) => {
  try {
    const res = await get(`${endpoint}`, authToken, options);
    if (!res.ok) {
      throw new StatusCodeError(res.status, endpoint, res);
    }
    const json = await res.json();
    return json;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};
