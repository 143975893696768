import styled from "styled-components";
import themeColors from "../../../../constants/themeColors";

export const ContactsAreaAddSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: solid 1px ${themeColors.background.offWhite};
  background: ${themeColors.background.white};
`

export const ContactsAreaCards = styled.div`
  display: grid;
  row-gap: 1.5rem;
  padding-bottom: 1.5rem;
  overflow: hidden;
`

export const ContactsAreaHeading = styled.h3`
  color: ${themeColors.background.dark};
  padding: 0 0.5rem;
  font-weight: 500;
  font-size: 1rem;
`
