import styled from "styled-components";

export const NotesListContainer = styled.div`
  display: grid;
  row-gap: 1rem;
`

export const NotesListAddButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 1rem;
  }
`
