import React, { useContext } from 'react';
import {
  Button,
  Form, Input, Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import PropTypes, { InferProps } from 'prop-types';
import { Helmet } from 'react-helmet';
import ThemedModal from '../ThemedModal';
import jobAppBoardPropTypes from './types/board';
import { JobAppBoardContext } from '../../contexts/JobAppBoardProvider';
import notifyError from '../../util/notifyError';

function EditJobBoardModal({
  visible, onClose, selectedBoard,
}: InferProps<typeof EditJobBoardModal.propTypes>) {
  const [form] = Form.useForm();
  const { updateJobAppBoard } = useContext(JobAppBoardContext);
  const [saving, setSaving] = React.useState(false);

  function clear() {
    form.resetFields();
  }

  function resetAndClose() {
    clear();
    onClose();
  }

  async function onOk(data: Store) {
    // setState(FormStates.Submitting);
    if (!selectedBoard) {
      throw new Error('No selected board for job board edit');
    }
    try {
      setSaving(true)
      const toPost = {
        name: data.name,
      };
      await updateJobAppBoard(selectedBoard.id, toPost);
      resetAndClose();
    } catch (err) {
      notifyError('Unable to edit job board', (err as Error).message);
    } finally {
      setSaving(false);
    }
  }

  const formName = 'edit-job-board';
  if (!selectedBoard) {
    return <div />;
  }

  return (
    <ThemedModal
      title={`Rename ${selectedBoard.name}`}
      open={visible}
      onCancel={resetAndClose}
      destroyOnClose
      footer={(
        <Space>
          <Button
            onClick={resetAndClose}
          >
            Close
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form={formName}
            disabled={saving}
          >
            Save
          </Button>
        </Space>
      )}
      okButtonProps={{
        htmlType: 'submit',
        form: formName,
      }}
    >
      <Helmet>
        <title>
          Rename board
          {' '}
          {selectedBoard.name}
        </title>
      </Helmet>
      <Form
        name={formName}
        onFinish={onOk}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Name"
          name="name"
          initialValue={selectedBoard.name}
          rules={[{
            required: true,
            message: 'Name is required',
          }, {
            type: 'string',
            max: 200,
            message: 'Name is too long',
          }]}
        >
          <Input
            autoFocus
            defaultValue={selectedBoard.name}
            placeholder="New board name"
          />
        </Form.Item>
      </Form>
    </ThemedModal>
  );
}

EditJobBoardModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedBoard: jobAppBoardPropTypes.isRequired,
};

export default EditJobBoardModal;
