/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Dropdown, Popover, Space, Table,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import dayjs from 'dayjs';
import StepDescription from '../StepDescription';
import StepTitle from '../StepTitle';
import themeColors from '../../../../constants/themeColors';
import BulkJobApp from '../../../../types/BulkJobApp';
import DangerText from '../../../theme/DangerText';
import WarningText from '../../../theme/WarningText';
import SuccessText from '../../../theme/SuccessText';

const propertyToDisplayNames = new Map<keyof BulkJobApp, string>([
  ['company', 'Company'],
  ['appliedAt', 'Date Applied'],
  ['description', 'Description'],
  ['title', 'Job Position'],
  ['url', 'Job Post Link'],
  ['columnName', 'List Name'],
  ['notes', 'Notes'],
  ['salary', 'Salary'],
]);

const allAvailableProperties = Array.from(propertyToDisplayNames.keys());

const requiredProperties: Array<keyof BulkJobApp> = ['company'];

const propertyValidations: {[index: string]: (val: string) => boolean} = {
  appliedAt: (val: string) => dayjs(val).isValid(),
};

function csvRowToJobApp(
  userCsvRow: string[],
  columnMapping: Array<keyof BulkJobApp|undefined>,
): BulkJobApp {
  const jobApp: Record<string, string> = {};
  for (let columnIndex = 0; columnIndex < userCsvRow.length; columnIndex += 1) {
    const fieldName = columnMapping[columnIndex];
    // If there is no field name, then this column was not mapped to anything
    if (fieldName) {
      const value = userCsvRow[columnIndex];
      jobApp[fieldName] = value;
    }
  }
  return jobApp as unknown as BulkJobApp;
}

function csvRowsToMapping(
  userCsvRows: string[][],
  columnMapping: Array<keyof BulkJobApp|undefined>,
): BulkJobApp[] {
  return userCsvRows.map((row) => csvRowToJobApp(row, columnMapping));
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTable = styled(Table)`
  margin-top: 2rem;
  .ant-table-cell {
    .editable .anticon-edit {
      opacity: 0;
    }
    &:hover {
      .editable .anticon-edit {
        opacity: 1;
      }
    }
  }
`;

const TableHeaderContainer = styled.a`
  display: flex;
  min-width: 7rem;
  justify-content: space-between;
  align-items: center;
  color: ${themeColors.background.dark};
  > span:first-child {
    padding-right: 1.5rem;
  }
`;

interface TableHeaderCellProps {
  selectableProperties: Array<keyof BulkJobApp>,
  onPropertyChange: (newProperty?: keyof BulkJobApp) => void,
  selectedProperty: keyof BulkJobApp|undefined,
  defaultValue: string,
  icon: React.ReactNode,
  tooltipText: React.ReactNode,
}

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  selectableProperties,
  onPropertyChange,
  defaultValue,
  selectedProperty,
  icon,
  tooltipText,
}) => {
  const [visible, setVisible] = useState(false);
  const prettyPropertyName = selectedProperty ? propertyToDisplayNames.get(selectedProperty) : null;
  const selectedPropertyDisplay = !prettyPropertyName
    ? (
      <span>
        {defaultValue}
        <DownOutlined style={{
          fontSize: '0.7rem',
          marginLeft: '0.4rem',
        }}
        />
      </span>
    )
    : (
      <div>
        <span style={{ fontSize: '0.8rem', textDecoration: 'line-through', display: 'block' }}>{defaultValue}</span>
        {prettyPropertyName}
        <DownOutlined style={{
          fontSize: '0.7rem',
          marginLeft: '0.4rem',
        }}
        />
      </div>
    );

  useEffect(() => {
    setVisible(false);
  }, [selectedProperty]);

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: selectableProperties.map((property) => ({
          key: property,
          label: propertyToDisplayNames.get(property),
          onClick: () => {
            onPropertyChange(property);
          }
        })),
      }}
      open={visible}
      onOpenChange={setVisible}
    >
      {tooltipText
        ? (
          <TableHeaderContainer>
            <span style={{ fontWeight: 600 }}>
              {selectedPropertyDisplay}
            </span>
            <Popover content={tooltipText}>
              <div>
                {icon}
              </div>
            </Popover>
          </TableHeaderContainer>
        )
        : (
          <TableHeaderContainer>
            <span style={{ fontWeight: 600 }}>
              {selectedPropertyDisplay}
            </span>
            {icon}
          </TableHeaderContainer>
        )}
    </Dropdown>
  );
};

interface TableRowCellProps {
  currentValue: string,
  onChange: (newValue: string) => void;
}

const TableRowCell: React.FC<TableRowCellProps> = ({
  currentValue,
  onChange,
}) => (
  <>
    <Text
      className="editable"
      editable={{ onChange }}
      style={{
        left: 0,
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      {currentValue}
    </Text>
  </>
);

interface MapFieldsProps {
  /**
   * When the user confirms a valid mapping
   */
  onMappingSet: (jobApps: BulkJobApp[]) => void,
  /**
   * If the user edits a cell, save the edit into the uploaded csv
   */
  onCsvUpdated: (updatedCsv: string[][]) => void,
  /**
   * When the user wants to re-upload their CSV
   */
  onGoBack: () => void,
  /**
   * The contents of the CSV. The outer array contains the rows, and each inner array contains the
   * elements.
   */
  csvContents: string[][],
  /**
   * The properties that corresponds to each column of the csv contents
   */
  selectedProperties: Array<keyof BulkJobApp|undefined>,
  /**
   * When a property that corresponds to a column of the csv contents changed, the parent is
   * updated
   */
  onSelectedPropertiesUpdate: (updatedProps: Array<keyof BulkJobApp|undefined>) => void,
}

const MapFields: React.FC<MapFieldsProps> = ({
  onMappingSet,
  onCsvUpdated,
  onGoBack,
  selectedProperties,
  onSelectedPropertiesUpdate,
  csvContents,
}) => {
  // The first row represents the headers/column names
  const headerRow = csvContents[0];
  // A 2D array, where each top level array/row represents a single job application.
  const [fieldRows, setFieldRows] = useState(csvContents.slice(1, csvContents.length));
  /**
   * If the moves onto the the next step of the wizard, we want their manual edits to the field
   * cells saved if they click Back so we save it in the parent component.
   */
  useEffect(() => {
    onCsvUpdated([
      headerRow,
      ...fieldRows,
    ]);
  }, [headerRow, fieldRows, onCsvUpdated]);

  const missingRequiredProperties = requiredProperties
    .filter((requiredProp) => !selectedProperties.find((prop) => prop === requiredProp));

  const missingRequiredPropertyNames = missingRequiredProperties
    .map((prop) => propertyToDisplayNames.get(prop));

  /**
   * Save the property in an index when a user selects a mapping for a column.
   *
   * @param newPropName The property the user just selected for a column
   * @param columnIndex The index of the column the user chose for the property
   */
  const onPropertySelect = (newPropName: keyof BulkJobApp|undefined, columnIndex: number) => {
    const currentSelectedProperties = [...selectedProperties];
    // Clear the property if it already exists
    for (let i = 0; i < headerRow.length; i += 1) {
      if (currentSelectedProperties[i] === newPropName) {
        currentSelectedProperties[i] = undefined;
      }
    }
    currentSelectedProperties[columnIndex] = newPropName;
    onSelectedPropertiesUpdate(currentSelectedProperties);
  };

  const someRequiredPropertiesMissingData = requiredProperties
    .some((prop) => {
      const requiredColIndex = selectedProperties.findIndex((selected) => selected === prop);
      if (requiredColIndex < 0) {
      // This required prop has not been set yet
        return false;
      }
      // If some row has missing data in the required column, retrn false
      return fieldRows.some((row) => !row[requiredColIndex]);
    });

  const somePropertiesHaveInvalidValues = selectedProperties
    .some((columnProp, columnIndex) => {
      if (!columnProp || !(columnProp in propertyValidations)) {
        // Either this column is not ampped to anything or it doesn't have a validation function
        return false;
      }

      const isValid = propertyValidations[columnProp];
      // If some row has missing data in the required column, retrn false
      return fieldRows.some((row) => !isValid(row[columnIndex]));
    });

  const tableColumns = headerRow.map((usersOriginalColumnName, columnIndex) => {
    let icon;
    let tooltipText;
    const selectedProperty = selectedProperties[columnIndex];
    const isRequired = selectedProperty ? requiredProperties.includes(selectedProperty) : false;
    const someRowsMissingData = isRequired && fieldRows.some((row) => !row[columnIndex]);
    const someRowsHaveInvalidData = selectedProperty
      && selectedProperty in propertyValidations
      && fieldRows.some((row) => {
        const isValid = propertyValidations[selectedProperty];
        return !isValid(row[columnIndex]);
      });

    if (missingRequiredProperties.length > 0) {
      icon = <DangerText><CloseCircleOutlined /></DangerText>;
      tooltipText = (
        <DangerText>
          You are missing one or more of the required properties:
          {' '}
          {missingRequiredPropertyNames.join(', ')}
        </DangerText>
      );
    } else if (someRowsHaveInvalidData) {
      icon = <DangerText><CloseCircleOutlined /></DangerText>;
      tooltipText = (
        <DangerText>
          Some rows have invalid values for this column
          {' '}
          {missingRequiredPropertyNames.join(', ')}
        </DangerText>
      );
    } else if (!selectedProperties[columnIndex]) {
      icon = <WarningText><ExclamationCircleOutlined /></WarningText>;
      tooltipText = 'You haven\'t selected a property for this column. This column\'s information will be lost.';
    } else if (someRowsMissingData) {
      icon = <DangerText><CloseCircleOutlined /></DangerText>;
      tooltipText = (
        <DangerText>
          Some data in this column is missing. All job apps are required to have data for
          {(selectedProperty && ` "${propertyToDisplayNames.get(selectedProperty)}".`) || ' this column.'}
        </DangerText>
      );
    } else {
      icon = <SuccessText><CheckCircleOutlined /></SuccessText>;
      tooltipText = '';
    }

    return ({
      title: (
        <TableHeaderCell
          selectableProperties={allAvailableProperties}
          onPropertyChange={(newProp) => onPropertySelect(newProp, columnIndex)}
          selectedProperty={selectedProperty}
          defaultValue={usersOriginalColumnName}
          icon={icon}
          tooltipText={tooltipText}
        />),
      dataIndex: columnIndex,
      key: `${usersOriginalColumnName}${columnIndex}`,
      width: '15rem',
    });
  });

  const tableRows = fieldRows.map((row, rowIndex) => {
    const rowData: Record<string, React.ReactNode> = {};
    row.forEach((cellValue, columnIndex) => {
      rowData[columnIndex] = (
        <TableRowCell
          currentValue={cellValue}
          onChange={(newVal) => {
            const fieldRowsClone = [...fieldRows];
            const rowClone = [...fieldRowsClone[rowIndex]];
            rowClone[columnIndex] = newVal;
            fieldRowsClone[rowIndex] = rowClone;
            setFieldRows(fieldRowsClone);
          }}
        />
      );
      rowData.key = columnIndex;
    });
    return rowData;
  });

  return (
    <Wrapper>
      <StepTitle>Configure Fields</StepTitle>
      <StepDescription style={{ textAlign: 'center' }}>
        Match what you have with what&apos;s on Stipplo.
        <br />
        Properties colored in
        {' '}
        <DangerText>red</DangerText>
        {' '}
        are required.
      </StepDescription>
      <Space direction="vertical" size="middle" style={{marginBottom: '3rem'}}>
        <StyledTable
          bordered
          dataSource={tableRows}
          columns={tableColumns}
          style={{ minWidth: '420px' }}
          pagination={{
            position: ['bottomCenter']
          }}
          scroll={{
          // Puts column titles on a single line, avoiding words collapsing into second lines
            x: 'max-content',
          }}
        />
        <Space style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Button
            onClick={onGoBack}
            style={{ width: '100%' }}
          >
          Back
          </Button>
          <Button
          // size="large"
            onClick={() => onMappingSet(csvRowsToMapping(fieldRows, selectedProperties))}
            style={{ width: '100%' }}
            disabled={missingRequiredProperties.length > 0
            || someRequiredPropertiesMissingData
            || somePropertiesHaveInvalidValues}
            type="primary"
          >
          Continue
          </Button>
        </Space>
      </Space>
    </Wrapper>
  );
};

export default MapFields;
