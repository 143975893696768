class APIRoutes {
  static get base() {
    // Usually http://localhost:8081/api
    return process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_PRODUCTION_URL
      : process.env.REACT_APP_API_DEVELOPMENT_URL;
  }

  static get login() {
    return `${this.base}/login`;
  }

  static get logout() {
    return `${this.base}/logout`;
  }

  static get register() {
    return `${this.base}/signup`;
  }

  static get authenticated() {
    return `${this.base}/authenticated`;
  }

  static get stripeBillingPortal() {
    return `${this.base}/payments/create-customer-portal-session`;
  }

  static get import() {
    return `${this.base}/import`;
  }

  static user(userId: string) {
    return {
      me: `${this.base}/users/@me`,
      jobs: `${this.base}/users/${userId}/jobs`,
      checkIfInMailchimp: `${this.base}/users/mailchimp`,
    };
  }

  static get jobAppBoards() {
    return `${this.base}/jobappboards`;
  }

  static jobAppBoard(boardId: string) {
    return `${this.base}/jobappboards/${boardId}`;
  }

  static columns(boardId: string) {
    return `${this.jobAppBoards}/${boardId}/columns`;
  }

  static column(boardId: string, columnId: string) {
    return `${this.jobAppBoards}/${boardId}/columns/${columnId}`;
  }

  static jobApps(boardId: string, columnId: string) {
    return `${this.columns(boardId)}/${columnId}/jobapps`;
  }

  static jobAppsSort(boardId: string, columnId: string) {
    return `${this.jobApps(boardId, columnId)}/sort`;
  }

  static jobApp(boardId: string, columnId: string, jobAppId: string) {
    return `${this.jobApps(boardId, columnId)}/${jobAppId}`;
  }

  static jobAppDocuments(boardId: string, columnId: string, jobAppId: string) {
    return `${this.jobApp(boardId, columnId, jobAppId)}/documents`;
  }

  static jobAppDocument(boardId: string, columnId: string, jobAppId: string, documentId: string) {
    return `${this.jobAppDocuments(boardId, columnId, jobAppId)}/${documentId}`;
  }

  static jobAppInterviews(boardId: string, columnId: string, jobAppId: string) {
    return `${this.jobApp(boardId, columnId, jobAppId)}/interviews`;
  }

  static jobAppInterview(boardId: string, columnId: string, jobAppId: string, interviewId: string) {
    return `${this.jobAppInterviews(boardId, columnId, jobAppId)}/${interviewId}`;
  }

  static jobAppInterviewQuestions(
    boardId: string,
    columnId: string,
    jobAppId: string,
    interviewId: string,
  ) {
    return `${this.jobAppInterview(boardId, columnId, jobAppId, interviewId)}/questions`;
  }

  static jobAppInterviewQuestion(
    boardId: string,
    columnId: string,
    jobAppId: string,
    interviewId: string,
    interviewQuestionId: string,
  ) {
    return `${this.jobAppInterviewQuestions(boardId, columnId, jobAppId, interviewId)}/${interviewQuestionId}`;
  }

  static scrapeJobApp(url: string) {
    return `${this.base}/scraper/jobapp?url=${encodeURIComponent(url)}`;
  }

  static documents() {
    return `${this.base}/documents`;
  }

  static document(docId: string) {
    return `${this.documents()}/${docId}`;
  }

  static payments = {
    checkoutSession: () => `${APIRoutes.base}/payments/create-checkout-session`,
    customerPortalSession: () => `${APIRoutes.base}/payments/create-customer-portal-session`,
  };
}

export default APIRoutes;
