import React, { useContext, useEffect, useState } from 'react';
import ThemedModal from '../../../../components/ThemedModal';
import { AppOrganizationViewContext } from '../../../../contexts/AppOrganizationViewProvider';
import notifyError from '../../../../util/notifyError';
import notifySuccess from '../../../../util/notifySuccess';
import { useOrganizationInvites } from '../../hooks';
import { OrganizationInvite } from '../../types';
import { InviteRow } from './InviteRow';

const OrganizationInviteModal: React.FC = () => {
  const { data, acceptInvite, declineInvite } = useOrganizationInvites()
  const [visible, setVisible] = useState(false)
  const [invites, setInvites] = useState<OrganizationInvite[]>([])
  const {
    refetchOrganizations
  } = useContext(AppOrganizationViewContext)

  const onInviteAccepted = async (invite: {
    id: string,
    name: string
  }) => {
    try {
      await acceptInvite(invite.id)
      notifySuccess(`You have accepted an invitation from ${invite.name}`)
    } catch (err) {
      notifyError(`Failed to accept invite (${(err as Error).message})`)
    }

    setInvites(invites.filter(i => i.id !== invite.id))
  }

  const onInviteDeclined = async (invite: {
    id: string,
    name: string
  }) => {
    try {
      await declineInvite(invite.id)
      notifySuccess(`You have declined an invitation from ${invite.name}`)
    } catch (err) {
      notifyError(`Failed to decline invite (${(err as Error).message})`)
    }

    setInvites(invites.filter(i => i.id !== invite.id))
  }

  useEffect(() => {
    if (data?.results.length) {
      setInvites(data.results)
      setVisible(true)
    }
  }, [data])

  useEffect(() => {
    if (invites.length === 0) {
      setVisible(false)
      // Organizations are re-fetched for the guided tour with react-sheperd to appear
      refetchOrganizations()
    }
  }, [invites.length, refetchOrganizations])

  return (
    <ThemedModal
      title={`You have ${invites.length} pending organization invites!`}
      open={visible}
      closable={false}
      footer={null}
    >
      <div>
        If you accept an invitation, a new board will automatically be created for you where your organization can track your job progress.
        <div style={{marginTop: '1.5rem'}}>
          {invites.map((invite) => (
            <InviteRow
              key={invite.id}
              organization={invite.organization}
              onAccept={() => onInviteAccepted({
                id: invite.id,
                name: invite.organization.name
              })}
              onDecline={() => onInviteDeclined({
                id: invite.id,
                name: invite.organization.name
              })}
            />
          ))}
        </div>
      </div>
    </ThemedModal>
  );
};

export default OrganizationInviteModal;
