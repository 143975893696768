import { Input } from "antd";
import styled from "styled-components";
import themeColors from "../../../../../../constants/themeColors";

export const InterviewQuestionAnswerTextAreaStyled = styled(Input.TextArea)`
  background: white;
  border: none;
  resize: none;
  padding: 10px;
  border: solid 1px ${themeColors.background.mediumGray};
  min-height: 100px !important;
  height: 100px;
  border-radius: 5px;
  &:hover {
    background: ${themeColors.background.offWhite};
  }
`

