import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Popover, Spin } from "antd";
import { useContext, useState } from "react";
import themeColors from "../../../../constants/themeColors";
import { MemberNotificationsContext } from "../../../../contexts/MemberNotificationsProvider";
import { NotificationsPopoverContainer, NotificationsPopoverTitle } from "./NotificationsPopover.styles";
import Notification from './Notification/Notification'
import notifyError from "../../../../util/notifyError";

interface Props {

}

const NotificationsPopover: React.FC<Props> = () => {
  const {
    notifications,
    error,
    readAllNotifications
  } = useContext(MemberNotificationsContext)
  const [readingAllNotifications, setReadingAllNotifications] = useState(false)
  const [visible, setVisible] = useState(false)

  if (!notifications) {
    return <Spin />
  }

  const unseenNotifications = notifications.filter((notification) => notification.status === 'unseen')

  const onClickReadAllNotifications = async () => {
    try {
      setReadingAllNotifications(true)
      await readAllNotifications()
    } catch (err) {
      notifyError(`Failed to read all notifications`, (err as Error).message)
    } finally {
      setReadingAllNotifications(false)
    }
  }

  const onVisibleChange = (visible: boolean) => {
    setVisible(visible)
  }

  return     <Badge
    showZero={false}
    offset={[-25, 10]}
    size="small"
    count={unseenNotifications.length}
  ><Popover
      open={visible}
      onOpenChange={onVisibleChange}
      placement="bottomRight"
      trigger="click"
      overlayStyle={{
        maxWidth: '400px',
        width: '100%',
      }}
      content={(
        <div style={{
          overflow: 'auto',
          maxHeight: '600px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 0.5rem 1rem 0.5rem',
            alignItems: 'center'
          }}>
            <NotificationsPopoverTitle>Notifications</NotificationsPopoverTitle>
            {unseenNotifications.length > 0 ? <Button 
              type="link"
              disabled={readingAllNotifications}
              onClick={onClickReadAllNotifications}
            >
            Mark all as read
            </Button> : <Button disabled />}

          </div>
          <NotificationsPopoverContainer>
            {error && <>
              <br />
              <span style={{
                color: themeColors.semantic.danger
              }}>
            Sorry, something went wrong while getting your notifications.
              </span>
              {/* <br /> */}
            </>}
            {!notifications && <Spin />}
            {notifications && notifications.map((notification, index) => (
              <Notification notification={notification} key={notification.id} onClose={() => onVisibleChange(false)} />
            ))}
            <div style={{
              color: themeColors.background.mediumGray,
              textAlign: 'center',
              margin: '1rem 0 1.5rem 0'
            }}>
              {notifications && <span>
                These are all your notifications within the past 30 days.
              </span>}
            </div>
          </NotificationsPopoverContainer>
        </div>
      )}
    >
      <Button
        type="text"
        icon={<FontAwesomeIcon icon={faBell} />}
        shape="circle"
        size="large"
      />
    </Popover>
  </Badge>

}

export default NotificationsPopover;
