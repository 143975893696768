import React, { useContext, useEffect } from 'react';
import {
  Drawer, Button, message,
} from 'antd';
import styled from 'styled-components';
import { navigate, useLocation } from '@reach/router';
import * as Sentry from '@sentry/react';
import {
  faArrowLeft, faCog, faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pages from '../../constants/pages';
import useAuthZero from '../../hooks/useAuthZero';
import themeColors from '../../constants/themeColors';
import MenuSideBarContents from './MenuSideBarContents';
import { ConfirmModalContext } from '../../contexts/ConfirmModalProvider';

const Header = styled.div`
  margin: 20px 20px 0;
  display: flex;
  align-items: center;
  user-select: none;
  button {
    margin-right: 20px;
    color: rgba(255,255,255,.85);
    &:hover, &:active, &:focus, &:visited {
      color: white;
    }
  }
  h1 {
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 2.5em;
  }
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  justify-content: space-between;
`;

const UserArea = styled.div`
  display: flex;
  justify-content: space-between;
  justify-self: flex-end;
  color: white;
  padding: 20px 20px;
`;

const UserAreaName = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const UserDisplayName = styled.span`
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserAreaButtons = styled.div`
  display: flex;
  button {
    margin-left: 10px;
  }
  button:first-child svg {
    color: white;
  }
`;

const drawerBodyStyles: React.CSSProperties = {
  background: themeColors.background.dark,
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
};

function MenuSideBarMobile({
  visible,
  onClose,
}: {
  visible: boolean,
  onClose: () => void
}) {
  const location = useLocation();
  const { pathname } = location;
  const confirmModalContext = useContext(ConfirmModalContext);
  const { user, logout } = useAuthZero();

  useEffect(() => {
    onClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function tryLogout() {
    try {
      logout({ returnTo: window.location.origin });
      message.success('Successfully logged out');
    } catch (err) {
      message.error('Failed to log out');
      Sentry.captureException(err);
    }
  }

  async function confirmLogout() {
    onClose();
    confirmModalContext.showConfirmModal({
      content: 'Are you sure you want to logout?',
      onOk: async () => {
        await tryLogout();
      },
    });
  }

  return (
    <Drawer
      style={{ padding: 0 }}
      placement="left"
      closable={false}
      bodyStyle={drawerBodyStyles}
      width={300}
      visible={visible}
      onClose={onClose}
    >
      <Header>
        <Button
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          size="large"
          type="text"
          onClick={onClose}
        />
        <a href={pages.APP}>
          <h1>stipplo</h1>
        </a>
      </Header>
      <Menu>
        <MenuSideBarContents />
        <UserArea>
          <UserAreaName>
            <UserDisplayName>{user?.name}</UserDisplayName>
          </UserAreaName>
          <UserAreaButtons>
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faCog} />}
              size="large"
              onClick={() => navigate(pages.SETTINGS_ACCOUNT)}
            />
            <Button
              danger
              type="text"
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
              size="large"
              onClick={confirmLogout}
            />
          </UserAreaButtons>
        </UserArea>
      </Menu>
    </Drawer>
  );
}

export default MenuSideBarMobile;
