import { getJson } from '../xhr';
import { stringify } from 'qs'
import JobAppInterviewWithJobApp from '../../types/JobAppInterviewWithJobApp';

interface GetJobAppInterviewsResponse {
  data: JobAppInterviewWithJobApp[],
  total: number
}

export type GetJobAppInterviewsOptions = {
  offset: number,
  limit: number,
  filters?: {
    jobAppId?: string,
    date?: {
      gte?: string,
      lte?: string,
    }
  }
}

export default function xhrGetJobAppInterviews(
  accessToken: string,
  options: GetJobAppInterviewsOptions
): Promise<GetJobAppInterviewsResponse> {
  const filtersQuery = stringify(options)
  return getJson(
    `/interviews?${filtersQuery}`,
    accessToken
  )
}
