import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import getSubscriptions from '../adapters/subscriptions/getSubscriptions';
import { getJson } from '../adapters/xhr';
import { UserContext } from './UserProvider';

type Subscription = {};

type CheckoutSession = {
  // Stripe's price nickname
  plan: string;
};

type UserSubscriptionsContextData = {
  subscriptions: Subscription[]|null,
  getSubscriptionFromCheckoutSession: (sessionId: string) => Promise<CheckoutSession>
};

export const UserSubscriptionsContext = React.createContext<UserSubscriptionsContextData>({
  subscriptions: [],
  getSubscriptionFromCheckoutSession: async () => ({ plan: '' }),
});

export const UserSubscriptionsProvider: React.FC = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]|null>(null);
  const { getAccessToken } = useContext(UserContext);

  useEffect(() => {
    async function fetchSubscriptions() {
      const accessToken = await getAccessToken();
      const fetched = await getSubscriptions(accessToken);
      setSubscriptions(fetched);
    }
    fetchSubscriptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubscriptionFromCheckoutSession = useCallback(async (sessionId: string) => {
    const token = await getAccessToken();
    const session = await getJson(`/payments/session?sessionId=${sessionId}`, token);
    return session;
  }, [getAccessToken]);

  return (
    <UserSubscriptionsContext.Provider value={{
      subscriptions,
      getSubscriptionFromCheckoutSession,
    }}
    >
      {children}
    </UserSubscriptionsContext.Provider>
  );
};
