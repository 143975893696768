import { LeftOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd"
import dayjs from "dayjs"
import notifyError from "../../../../util/notifyError";
import notifySuccess from "../../../../util/notifySuccess";
import { useUpdateOrganizationGoal } from "../../hooks";
import { GoalStatus } from "../../types";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime)

interface Props {
  id: string;
  title: string
  description: string | null;
  dueTimestamp?: number | null
  status: GoalStatus;
  canBeUpdated: boolean;
  onClickClose?: () => void
  recurringInterval?: {
    unit: string;
    value: number;
  } | null;
}

const recurringText: Record<string, string> = {
  days: 'daily ',
  weeks: 'weekly ',
  months: 'monthly ',
}

export const OrganizationGoalNestedDrawerContent = ({
  id,
  title,
  description,
  dueTimestamp,
  status,
  canBeUpdated,
  onClickClose,
  recurringInterval,
}: Props) => {
  const { mutateAsync, status: updateStatus } = useUpdateOrganizationGoal()

  const onClickMarkComplete = async () => {
    try {
      await mutateAsync({
        id,
        data: {
          status: GoalStatus.Complete
        }
      })
      onClickClose?.()
      notifySuccess(`Goal marked as complete!`)
    } catch (err) {
      notifyError(`Sorry, something went wrong`, err as Error)
    }
  }

  const onClickMarkIncomplete = async () => {
    try {
      await mutateAsync({
        id,
        data: {
          status: GoalStatus.Incomplete
        }
      })
      onClickClose?.()
      notifySuccess(`Goal marked as incomplete`)
    } catch (err) {
      notifyError(`Sorry, something went wrong`, err as Error)
    }
  }

  return <Space size="middle" direction="vertical" style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
    <Button style={{padding: 0}} type="link" onClick={onClickClose} icon={<LeftOutlined />}>Back</Button>
    <div>
      <Typography.Text type="secondary" style={{display: 'block'}}>
        {!dueTimestamp && 'No due date'}
        {dueTimestamp && `Due on ${dayjs.unix(dueTimestamp).format('MMM D, YYYY')}`}
      </Typography.Text>
      <Typography.Text strong style={{display: 'block'}}>
        {title}
      </Typography.Text>
      {description && <Typography.Text style={{display: 'block'}}>
        {description}
      </Typography.Text>}
      {recurringInterval && dueTimestamp && <Typography.Text type={"secondary"} style={{
        paddingTop: '8px',
        paddingBottom: '8px',
        display: 'block'
      }}>
        This is a {recurringText[recurringInterval.unit]}recurring goal. It will reset in {dayjs.unix(dueTimestamp).fromNow(true)}.
      </Typography.Text>}
    </div>
    {!canBeUpdated && <Typography.Text type="danger" style={{display: 'block'}}>
      This goal is past its due date and can no longer be updated.
    </Typography.Text>}
    <Space direction="vertical" style={{display: 'flex'}}>
      {status === GoalStatus.Incomplete && <Button
        type="primary"
        block
        onClick={onClickMarkComplete}
        loading={updateStatus === 'loading'}
        disabled={!canBeUpdated}
      >
        Mark as Completed
      </Button>}
      {status === GoalStatus.Complete && <Button 
        type="primary"
        danger
        block
        onClick={onClickMarkIncomplete}
        loading={updateStatus === 'loading'}
        disabled={!canBeUpdated}
      >
        Mark as Incomplete
      </Button>}
      <Button block onClick={onClickClose}>
        Close
      </Button>
    </Space>
  </Space>
}
