import { get } from '../xhr';

async function triggerDownload(res: Response, fileName: string) {
  const blob = await res.blob()
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.click()
  setTimeout(() => URL.revokeObjectURL(link.href), 0)
}

export default async function downloadExports(accessToken: string) {
  const jobs = await get('/exports/@me/jobs', accessToken, {
    headers: {
      Accept: 'text/csv',
      'content-type': ''
    }
  });
  const contacts = await get('/exports/@me/contacts', accessToken, {
    headers: {
      Accept: 'text/csv',
      'content-type': ''
    }
  });

  await triggerDownload(jobs, 'jobs.csv')
  await triggerDownload(contacts, 'contacts.csv')
}
