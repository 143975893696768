import React, { useState, useContext } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import JobAppBoard from '../../types/JobAppBoard';
import {ConfirmModalContext} from '../../contexts/ConfirmModalProvider';
import EditJobBoardModal from './EditJobBoardModal';
import pages from '../../constants/pages';
import themeColors from '../../constants/themeColors';
import notifyError from '../../util/notifyError';
import { JobAppBoardContext } from '../../contexts/JobAppBoardProvider';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants/dateTimeFormats';
import { Button, Typography } from 'antd';
import { transparentize } from 'polished';

const BoardItem = styled.div`
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  border: solid 1px ${themeColors.background.offWhite};
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* the p element by default had some margin for some reason */
  > p { 
    margin: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

const BoardItemContainer = styled.div`
  width: 100%;
  min-width: 250px;
  outline: none;
  &:hover, &:focus, &:focus-within {
    box-shadow: 0px 4px 4px ${transparentize(0.9, themeColors.background.dark)};
    button {
      opacity: 1;
    }
  }
  &:active {
    box-shadow: 0px 1px 1px ${transparentize(0.9, themeColors.background.dark)};
  }
  transition: all 0.2s;
  border-radius: 15px;
  @media (max-width: 400px) {
    ${ItemContent} {
      > span {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  opacity: 0;
  align-items: center;
  height: 36px !important;
  width: 36px !important;
  border-radius: 5px;
  font-size: 1.3rem;
  background: transparent !important;
  color: ${themeColors.background.gray} !important;
  margin-left: 7px;
  border: none;
  &:hover, &:focus {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    color: ${(props) => (props.color === 'red' ? themeColors.semantic.danger : themeColors.background.dark)} !important;
  }
  transition: color .2s, box-shadow .2s;
`;

type BoardListItemProps = {
  board: JobAppBoard,
};

function BoardListItem({ board }: BoardListItemProps) {
  const [showEditBoardModal, setShowEditBoardModal] = useState(false);
  const confirmModalContext = useContext(ConfirmModalContext);
  const { deleteJobAppBoard } = useContext(JobAppBoardContext);

  function navigateToBoard(b: JobAppBoard) {
    return navigate(`${pages.BOARD}/${b.id}`);
  }

  async function deleteBoard(b: JobAppBoard) {
    confirmModalContext.showConfirmModal({
      title: `Are you sure you want to delete this board (${b.name})?`,
      content: 'This will permanently delete all jobs within this board.',
      okButton: <Button danger type="primary">Delete</Button>,
      onOk: async () => {
        try {
          await deleteJobAppBoard(b.id);
        } catch (err) {
          notifyError('Unable to delete board', (err as Error).message);
        }
      },
    });
  }

  return (
    <BoardItemContainer tabIndex={0}>
      <EditJobBoardModal
        onClose={() => setShowEditBoardModal(false)}
        visible={showEditBoardModal}
        selectedBoard={board}
      />
      <BoardItem
      >
        <ItemContent
          onClick={() => navigateToBoard(board)}
        >
          <Typography.Text type="secondary">{dayjs(board.createdAt).format(DATE_FORMAT)}</Typography.Text>
          <Typography.Text strong>{board.name}</Typography.Text>
          <Typography.Text>{`${board.jobCount} applications`}</Typography.Text>
        </ItemContent>
        <div style={{ display: 'flex' }}>
          <StyledButton
            onClick={() => setShowEditBoardModal(true)}
          >
            <EditOutlined />
          </StyledButton>
          <StyledButton
            onClick={() => deleteBoard(board)}
            color="red"
          >
            <DeleteOutlined />
          </StyledButton>
        </div>
      </BoardItem>
    </BoardItemContainer>
  );
}

export default BoardListItem;
