import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

const Column = styled.div`
  min-width: 335px;
  width: 335px;
  background: white;
  margin-right: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  border: solid 1px #F0F2F5;
`;

const ColumnHeader = styled.div`
  padding: 7px 17px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const ColumnHeaderNameSkeleton = styled(Skeleton)`
  h3 {
    margin-bottom: 0 !important;
    margin-top: 8px !important;
    height: 19px !important; 
  }
`;

const ColumnHeaderJobCountSkeleton = styled(Skeleton)`
  h3 {
    height: 14px !important;
  }
`;

const SkeletonJobCard = styled(Skeleton)`
  h3 {
    height: 80px !important;
    padding: 5px 10px 5px 15px;
  }
  margin-bottom: 10px;
  border-radius: 5px;
`;

const ColumnBody = styled.div`
  padding: 15px;
  overflow: auto;
`;

function ColumnSkeleton() {
  return (
    <Column>
      <ColumnHeader>
        <ColumnHeaderNameSkeleton title paragraph={false} active />
        <ColumnHeaderJobCountSkeleton title paragraph={false} active />
      </ColumnHeader>
      <ColumnBody>
        <SkeletonJobCard title paragraph={false} />
        <SkeletonJobCard title paragraph={false} />
      </ColumnBody>
    </Column>
  );
}

export default ColumnSkeleton;
