import {
  Button,
  Form, Input, Space,
} from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { InterviewQuestionContext } from '../../../../../contexts/InterviewQuestionProvider';
import JobAppInterview from '../../../../../types/JobAppInterview';
import JobAppInterviewQuestion from '../../../../../types/JobAppInterviewQuestion';
import notifyError from '../../../../../util/notifyError';

const InterviewInputWrapper = styled(Form)`
  position: relative;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: grid;
  row-gap: 0.5rem;
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0;
`;

const StyledInput = styled(Input)`
`;

const StyledTextArea = styled(Input.TextArea)`
  min-height: 100px;
  max-height: 100px;
`;

type FormState = {
  question: string,
  answer: string,
};

interface Props {
  onClose: () => void;
  onAdded?: (question: JobAppInterviewQuestion) => void;
  interview: JobAppInterview;
}

const InterviewQuestionNewPrompt: React.FC<Props> = ({
  onClose,
  onAdded,
  interview,
}) => {
  const {
    createInterviewQuestion,
  } = useContext(InterviewQuestionContext)
  const [formState, setFormState] = useState<FormState>({
    question: '',
    answer: '',
  });
  const [adding, setAdding] = useState(false);

  function updateFormState(key: keyof FormState, value: string) {
    setFormState({
      ...formState,
      [key]: value,
    });
  }

  
  const onSave = async () => {
    if (!formState.question) {
      return;
    }
    try {
      setAdding(true);
      const added = await createInterviewQuestion({
        question: formState.question,
        answer: formState.answer,
        interviewId: interview.id,
      });
      onAdded?.(added);
    } catch (err) {
      notifyError('Failed to add new interview question', (err as Error).message);
    } finally {
      setAdding(false);
    }
  };

  return (
    <InterviewInputWrapper>
      <StyledFormItem>
        <StyledInput
          placeholder="New question"
          value={formState.question}
          onChange={(e) => updateFormState('question', e.target.value)}
        />
      </StyledFormItem>
      <StyledFormItem>
        <StyledTextArea
          placeholder="New answer"
          autoSize
          value={formState.answer}
          style={{
            minHeight: '100px',
            height: '100%'
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => updateFormState('answer', e.target.value)}
        />
      </StyledFormItem>
      <Space>
        <Button onClick={onClose} disabled={adding}>Cancel</Button>
        <Button htmlType="submit" onClick={() => onSave()} type="primary" disabled={!formState.question || adding}>Save</Button>
      </Space>
    </InterviewInputWrapper>
  );
};

export default InterviewQuestionNewPrompt;
