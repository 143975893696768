import { InputRef } from "antd";
import { useEffect, useRef } from "react";
import themeColors from "../../../../../../constants/themeColors";
import {
  InterviewQuestionQuestionInputStyled
} from "./InterviewQuestionQuestionInput.styles";

interface Props {
  text: string;
  onChange: (text: string) => void;
  onEnter: () => void
  focused: boolean
}

const InterviewQuestionQuestionInput: React.FC<Props> = ({
  text,
  onChange,
  onEnter,
  focused,
}) => {
  const inputRef = useRef<InputRef|null>(null);

  useEffect(() => {
    if (focused && inputRef) {
      inputRef.current?.focus()
    }
  }, [focused, inputRef])


  return <InterviewQuestionQuestionInputStyled
    spellCheck={false}
    ref={inputRef}
    onChange={(e) => onChange(e.target.value)}
    value={text} 
    onKeyPress={e => {
      if (e.key === 'Enter') {
        onEnter()
      }
    }}
    style={focused ? {
      background: 'white',
      border: `solid 1px ${themeColors.background.mediumGray}`,
    } : {}}
  />
}

export default InterviewQuestionQuestionInput;
