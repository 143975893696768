import { useContext, useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { CloudUploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import notifySuccess from '../util/notifySuccess';
import notifyError from '../util/notifyError';
import { DocumentContext } from '../contexts/DocumentProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import themeColors from '../constants/themeColors';

function beforeUpload(file: File) {
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    message.error('Only PDF files are supported');
  }
  const smallFile = file.size / 1024 / 1024 < 1;
  if (isPdf && !smallFile) {
    message.error('File must be smaller than 1MB!');
  }
  return isPdf && smallFile;
}

function UploadArea() {
  const { createDocument } = useContext(DocumentContext);
  const [filesUploaded, setFilesUploaded] = useState<File[]>([]);

  const onFileUploaded = (file: File) => {
    setFilesUploaded([
      ...filesUploaded,
      file,
    ])
  }

  return (
    <Dragger
      beforeUpload={beforeUpload}
      id="styledrag"
      name="file"
      showUploadList={{
        showDownloadIcon: true,
        showRemoveIcon: false,
        showPreviewIcon: true,
      }}
      itemRender={(originNode, file) => <span><FontAwesomeIcon style={{
        color: themeColors.semantic.success,
        marginRight: '0.5rem',
        marginTop: '1rem'
      }} icon={faCheckCircle} />{file.name}</span>}
      multiple
      accept=".pdf,application/pdf"
      customRequest={async ({
        file: origFile
      }) => {
        const file = origFile as File
        try {
          await createDocument(file.name, file);
          onFileUploaded(file)
          notifySuccess('Uploaded new document', file.name);
        } catch (err) {
          notifyError('Unable to upload document', (err as Error).message);
        }
      }}
    >
      
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined />
        
      </p>
      <p className="ant-upload-text">
        Click or drag files to this area to upload.
        <br />
        Only PDF files are accepted.
      </p>
      {/* <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
        band files
      </p> */}
    </Dragger>
  );
}

export default UploadArea;
