import React, { useEffect, useState } from 'react';
import {
  Button as AntButton, Button, DatePicker, Form, Input, Popover,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DATE_FORMAT_WITH_TIME } from '../../../../constants/dateTimeFormats';

interface NewInterview {
  date: Date;
  title: string;
}

interface AddInterviewPopoverProps {
  onInterviewAdded: (interview: NewInterview) => void;
}

const AddInterviewPopover: React.FC<AddInterviewPopoverProps> = ({
  onInterviewAdded,
}) => {
  const [showAddInterviewPopover, setShowAddInterviewPopover] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!showAddInterviewPopover) {
      form.resetFields();
    }
  }, [form, showAddInterviewPopover]);

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      onOpenChange={(visible) => setShowAddInterviewPopover(visible)}
      open={showAddInterviewPopover}
      content={(
        <Form
          form={form}
          onFinish={({ date, title }) => {
            onInterviewAdded({
              date,
              title,
            });
            setShowAddInterviewPopover(false);
          }}
          layout="vertical"
        >
          <Form.Item
            style={{
              maxWidth: '200px'
            }}
            name="date"
            label="Interview Date"
            rules={[{
              required: true,
              message: '',
            }]}
          >
            <DatePicker
              showTime={{ format: 'h:mm A' }}
              format={DATE_FORMAT_WITH_TIME}
            />
          </Form.Item>
          <Form.Item
            style={{
              maxWidth: '200px'
            }}
            name="title"
            label="Interview Title"
            rules={[{
              required: true,
              message: '',
            }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{
            marginBottom: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            <Button
              htmlType="submit"              
              type="primary"
              style={{
                width: '100%',
              }}
            >
                Add
            </Button>
          </Form.Item>
        </Form>
      )}
    >
      <AntButton
        onClick={() => setShowAddInterviewPopover(true)}
        icon={<FontAwesomeIcon icon={faPlus} />}
        size="middle"
      >
        <span style={{ paddingLeft: '0.5rem' }}>New</span>
      </AntButton>
    </Popover>
  );
};
export default AddInterviewPopover;
