import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Form, Input, Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Link } from '@reach/router';
import PropTypes, { InferProps } from 'prop-types';
import { Helmet } from 'react-helmet';
import ThemedModal from '../ThemedModal';
import themeColors from '../../constants/themeColors';
import pages from '../../constants/pages';
import { JobAppBoardContext } from '../../contexts/JobAppBoardProvider';
import { AppOrganizationViewContext } from '../../contexts/AppOrganizationViewProvider';

const ImportMsg = styled.div`
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  color: ${themeColors.background.gray};
  a {
    color: ${themeColors.brand.primary};
  }
`;

function AddJobBoardModal({
  visible, onClose,
}: InferProps<typeof AddJobBoardModal.propTypes>) {
  const [form] = Form.useForm();
  const { boards, addJobAppBoard } = useContext(JobAppBoardContext);
  const { selectedOrganization } = useContext(AppOrganizationViewContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    return () => setErrorMessage('')
  }, [visible])

  function clear() {
    form.resetFields();
  }

  function resetAndClose() {
    clear();
    onClose();
  }

  async function onOk(data: Store) {
    try {
      setAdding(true);
      setErrorMessage('');
      await addJobAppBoard(data.name);
      resetAndClose();
    } catch (err) {
      setErrorMessage((err as Error).message);
    } finally {
      setAdding(false);
    }
  }

  const formName = 'add-job-board';

  return (
    <ThemedModal
      title="Add a new job board"
      open={visible}
      onCancel={resetAndClose}
      destroyOnClose
      footer={(
        <Space>
          <Button
            onClick={resetAndClose}
          >
            Close
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form={formName}
            disabled={adding}
          >
            Save
          </Button>
        </Space>
      )}
    >
      <Helmet>
        <title>Add a new job tracking board</title>
      </Helmet>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      >
        <Form
          name={formName}
          onFinish={onOk}
          layout="vertical"
          form={form}
        >
          <Form.Item
            extra={errorMessage && (
              <span style={{
                color: 'red',
              }}
              >
                {errorMessage}
              </span>
            )}
            validateTrigger="onSubmit"
            label="Name"
            name="name"
            rules={[{
              required: true,
              message: 'Name is required',
            }, {
              type: 'string',
              max: 30,
              message: 'Name is too long',
            }, {
              validator: (rule, value, callback) => {
                if ((boards || []).find((board) => board.name === value)) {
                  callback('You already have a board with this name');
                } else {
                  callback();
                }
              },
            }]}
          >
            <Input
              autoFocus
              placeholder="Name of your new job board"
              onChange={() => setErrorMessage('')}
            />
          </Form.Item>
        </Form>
        {!selectedOrganization
          && <ImportMsg>
            Already tracking jobs with a spreadsheet?
            <Link to={pages.IMPORT_BOARD}> Import it.</Link>
          </ImportMsg>
        }
      </div>
    </ThemedModal>
  );
}

AddJobBoardModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  // board: jobAppBoardPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddJobBoardModal;
