import { getJson } from '../xhr';

export default function xhrGetJobAppInterview(
  accessToken: string,
  interviewId: string,
) {
  return getJson(
    `/interviews/${interviewId}`,
    accessToken
  )
}
