
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd'
import { debounce } from 'lodash';
import { useContext, useState } from 'react';
import { ContactsContext } from '../../../../../contexts/ContactsProvider';
import Contact from '../../../../../types/Contact';
import JobApp from '../../../../../types/JobApp';
import notifyError from '../../../../../util/notifyError';

interface SelectOptionData {
  value: string
  label: string
  contact: Contact
}

interface Props {
  jobApp: JobApp
  onAddedContact: (contact: Contact) => void;
}

const ContactSearch: React.FC<Props> = ({
  jobApp,
  onAddedContact
}) => {
  const {
    createContactForJobApp,
    searchContacts
  } = useContext(ContactsContext)
  const [searchValue, setSearchValue] = useState("");
  const [searching, setSearching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [matchedContacts, setMatchedContacts] = useState<Contact[]>([]);

  const onSearch = async (value: string) => {
    setSearchValue(value)
    setMatchedContacts([])
    if (!value) {
      return
    }
    setSearching(true);
    try {
      const foundJobApps = await searchContacts(value);
      setMatchedContacts(foundJobApps);
      // console.log('setting ', foundJobApps)
    } catch (err) {
      notifyError(`Failed to search for job applications`, (err as Error).message)
    } finally {
      setSearching(false);
    }
  }
  
  const onChange = async (contactId: string, option: SelectOptionData) => {
    setSaving(true);
    try {
      await createContactForJobApp(jobApp.id, option.contact);
      onAddedContact(option.contact)
    } catch (err) {
      notifyError(`Failed to add contact to job application`, (err as Error).message)
    } finally {
      setSaving(false);
    }
  }

  const options = matchedContacts
    .filter((contact) => !jobApp.contacts.find(c => c.id === contact.id))
    .map((contact) => {
      return {
        value: contact.id,
        label: contact.name,
        contact: contact,
        key: `${contact.name} ${contact.email} ${contact.phone} ${contact.companyPosition} ${contact.company}`
      }
    })


  return <Select
    style={{
      width: '100%'
    }}
    showSearch
    onSearch={debounce(onSearch, 300)}
    onClear={() => onSearch("")}
    allowClear
    disabled={saving}
    notFoundContent={searching
      ? 'Searching...'
      : searchValue
        ? 'No contacts found. Try a search for a different name, email, phone or company.'
        : 'Search for a contact via their name, company, email or phone.'}
    // @ts-ignore
    onChange={onChange}
    loading={searching}
    options={options}
    placeholder="Search for a contact to add"
    suffixIcon={<FontAwesomeIcon icon={faSearch} />}
    filterOption={(input, option) => {
      return option?.key?.toString?.()?.toLowerCase?.()?.includes(input.toLowerCase()) || false
    }}
  >
  </Select>
}

export default ContactSearch;
