import { Button } from "antd";
import { useContext, useState } from "react";
import { JobAppContext } from "../../../contexts/JobAppProvider";
import JobApp from "../../../types/JobApp";
import notifyError from "../../../util/notifyError";
import HTMLContent from "../InterviewComponents/HTMLContent/HTMLContent";
import RichEditor from "../RichEditor";

interface Props {
  jobAppField: 'notes'|'description';
  jobApp: JobApp;
  onUpdatedJobApp: (jobApp: JobApp) => void;
}

const JobAppRichTextEditor: React.FC<Props> = ({
  jobAppField,
  jobApp,
  onUpdatedJobApp
}) => {
  const [editing, setEditing] = useState(false)
  const { updateJobApp } = useContext(JobAppContext)

  const onEdit = () => {
    setEditing(true);
  }

  async function onSave(newText: string) {
    try {
      const toPost = {
        [jobAppField]: newText,
      };
      const updated = await updateJobApp(jobApp.id, toPost);
      setEditing(false)
      onUpdatedJobApp(updated)
    } catch (err) {
      notifyError('Unable to save changes', (err as Error).message);
    }
  }


  return (
    <div>
      {editing && <RichEditor
        initialValue={jobApp[jobAppField] || ''} 
        onSave={onSave}
        onCancel={() => setEditing(false)} />
      }
      {jobApp[jobAppField] && <div style={{marginBottom: '0.5rem'}}>
        {!editing && <HTMLContent 
          onClick={onEdit}
          style={{
            cursor: 'pointer',
            maxHeight: 'initial',
            minHeight: '100px',
            width: 'auto'
          }}
          tabIndex={0}
          onKeyPress={e => e.key === 'Enter' ? onEdit() : undefined}
          dangerouslySetInnerHTML={{
            __html: jobApp[jobAppField] || '',
          }}>
        </HTMLContent>}
      </div>
      }
      {!editing && <Button onClick={onEdit}>Edit</Button>}
    </div>
  )
  
}

export default JobAppRichTextEditor
