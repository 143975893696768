import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input, Popconfirm, Space } from 'antd'
import { useState } from 'react';
import Contact from '../../../../../types/Contact';
import { ContactFormContainer } from './ContactForm.styles';

type FormValues = Pick<Contact, 'name'|'email'|'phone'|'company'|'companyPosition'|'notes'>

interface Props {
  onCancel: () => void;
  onSubmit: (values: FormValues) => Promise<void>
  initialValues?: FormValues
  onClickDelete?: () => void
  deleting?: boolean
}

const ContactForm: React.FC<Props> = ({
  onCancel,
  onSubmit,
  onClickDelete,
  initialValues,
  deleting,
}) => {
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const onClickCancel = () => {
    form.resetFields();
    onCancel();
  }

  const onFinish = async (values: FormValues) => {
    setSaving(true)
    await onSubmit(values);
    setSaving(false)
  }

  return <ContactFormContainer>
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        required
        name="name"
        rules={[{ required: true, message: 'A contact name is required' }]}
        initialValue={initialValues?.name}
      >
        <Input placeholder='Name' />
      </Form.Item>
      <Form.Item
        name="company"
        initialValue={initialValues?.company}
      >
        <Input placeholder='Company' />
      </Form.Item>
      <Form.Item
        name="companyPosition"
        initialValue={initialValues?.companyPosition}
      >
        <Input placeholder='Position' />
      </Form.Item>
      <Form.Item
        name="phone"
        initialValue={initialValues?.phone}
      >
        <Input prefix={<FontAwesomeIcon style={{ marginRight: '0.5rem' }} icon={faPhone} />} placeholder='Phone number' />
      </Form.Item>
      <Form.Item
        name="email"
        initialValue={initialValues?.email}
      >
        <Input prefix={<FontAwesomeIcon style={{ marginRight: '0.5rem' }} icon={faEnvelope} />} placeholder='Email' />
      </Form.Item>
      <Form.Item
        name="notes"
        initialValue={initialValues?.notes}
      >
        <Input.TextArea placeholder='Notes' />
      </Form.Item>
      <Form.Item>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Space>
            <Button onClick={onClickCancel} disabled={saving}>Cancel</Button>
            <Button type="primary" htmlType="submit" disabled={saving}>Save</Button>
          </Space>
          {onClickDelete && 
            <Popconfirm
              title="Are you sure you want to remove this contact?"
              onConfirm={onClickDelete}
              okButtonProps={{
                disabled: deleting,
              }}
            >
              <Button disabled={deleting} danger>
                Remove
              </Button>
            </Popconfirm>
          }
        </div>
      </Form.Item>
    </Form>
  </ContactFormContainer>
}

export default ContactForm;
