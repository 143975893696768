import JobAppInterviewQuestion from '../../types/JobAppInterviewQuestion';
import { patchForJson } from '../xhr';

export default function xhrUpdateInterviewQuestion(
  accessToken: string,
  questionId: string,
  updates: Partial<JobAppInterviewQuestion>
) {
  return patchForJson(
    `/interview-questions/${questionId}`, updates, accessToken
  )
}
