import { ModalProps } from 'antd';
import React, { useState, useEffect } from 'react';
import ThemedModal from './ThemedModal';

const maxWidth = 1200;

interface ResponsiveModalProps extends ModalProps {}

const ResponsiveModal: React.FC<ResponsiveModalProps> = (props) => {
  const {
    title, open, onCancel, footer, transitionName, maskTransitionName, children,
  } = props;
  const responsiveWidth = window.innerWidth * 0.95;
  const [width, setWidth] = useState(responsiveWidth > maxWidth ? maxWidth : responsiveWidth);
  useEffect(() => {
    function listener() {
      if (window.innerWidth * 0.95 > maxWidth) {
        // If the window is really large, constrain it
        if (width !== maxWidth) {
          setWidth(maxWidth);
        }
      } else {
        // The window is really small, set it relative to window width
        setWidth(window.innerWidth * 0.95);
      }
    }

    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  });

  return (
    <ThemedModal
      title={title}
      open={open}
      destroyOnClose
      width={width}
      onCancel={onCancel}
      footer={footer}
      transitionName={transitionName}
      maskTransitionName={maskTransitionName}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </ThemedModal>
  );
};

export default ResponsiveModal;
