import { deleteFor } from '../xhr';

export default function xhrDeleteJobAppNote(
  accessToken: string,
  id: string,
) {
  return deleteFor(
    `/job-app-notes/${id}`,
    accessToken
  );
}
