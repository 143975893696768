const brand = {
  primary: '#618CF7',
  primaryLight: '#638FE9',
};

const semantic = {
  success: '#03A87C',
  danger: '#D6635C',
  info: '#638FE9',
  warning: '#FFC702',
};

const background = {
  dark: '#47596B',
  gray: '#94A6B8',
  faintBlue: '#F6F9FF',
  offWhite: '#F1F2F4',
  white: '#FFF',
  mediumGray: '#BBBBBB'
};

const accents = {
  orange: '#F37E7E',
};

const themeColors = {
  accents,
  brand,
  semantic,
  background,
};

export default themeColors;
