import { useContext } from "react";
import { ShepherdOptionsWithType, ShepherdTour } from "react-shepherd";
import localStorageConstants from "../constants/localStorage";
import { AppOrganizationViewContext } from "./AppOrganizationViewProvider";

const { organizationsWelcomed } = localStorageConstants

const TourProvider: React.FC = ({children}) => {
  const { switchToOrganization, organizations } = useContext(AppOrganizationViewContext)
  const firstAvailableOrg = organizations?.[0]

  const steps: ShepherdOptionsWithType[] = [{
    id: 'intro',
    cancelIcon: {
      enabled: false,
    },
    title: '[1/3] Switch between views',
    text: [`<p>You can switch between your personal boards and your organization board through this dropdown.<br/><br/>Click next to automatically switch to the organization view for ${firstAvailableOrg?.name || ''}.</p>`],
    attachTo: {
      element: '.product-tour-org-dropdown-btn',
      on: 'right-end'
    },
    buttons: [{
      classes: 'ant-btn ant-btn-default',
      async action() {
        if (firstAvailableOrg) {
          await switchToOrganization(firstAvailableOrg.id)
        }
        this.next();
      },
      text: 'Next'
    }]
  }, {
    id: 'click-import',
    cancelIcon: {
      enabled: false,
    },
    title: '[2/3] Import an existing board',
    text: [`If you are already using a personal board to track your job search, you can copy your applications to a new board under ${firstAvailableOrg?.name || 'this organization'}.`],
    attachTo: {
      element: '.product-tour-copy-board-button',
      on: 'bottom'
    },
    buttons: [{
      async action() {
        this.next();
      },
      text: 'Next'
    }]
  }, {
    id: 'click-import',
    cancelIcon: {
      enabled: false,
    },
    title: `[3/3] Create a new board`,
    text: `<p>Alternatively, you may create a new board for ${firstAvailableOrg?.name || 'this organization'} and start fresh.<br/><br/>That's it! Good luck in your search!</p>`, 
    attachTo: {
      element: '.product-tour-add-board-button',
      on: 'bottom'
    },
    buttons: [{
      async action() {
        if (firstAvailableOrg) {
          const existingItem: string[] = JSON.parse(
            localStorage.getItem(organizationsWelcomed) || '[]');
          const newArray = Array.from(new Set([
            ...existingItem,
            firstAvailableOrg.id,
          ]))
          localStorage.setItem(organizationsWelcomed, JSON.stringify(newArray))
        }
        this.next();
      },
      text: 'End tour',
    }]
  }]
  

  return <ShepherdTour
    tourOptions={{
      defaultStepOptions: {
        cancelIcon: {
          enabled: true,
        },
      },
      useModalOverlay: true,
    }}
    steps={steps}
  >
    {children}
  </ShepherdTour>
}

export default TourProvider;
