import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import ReactGA from 'react-ga';
import GoogleAnalyticsConstants from '../constants/googleAnalytics';

ReactGA.initialize(GoogleAnalyticsConstants.trackingId);

/**
 * Component solely used to track page changes through
 * Reach Router, and send this info back to Analytics
 */
function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // Send a page view stat to Google Analytics every time location changes
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <></>
  );
}

export default GoogleAnalytics;
