import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import APIRoutes from '../../constants/APIRoutes';
import themeColors from '../../constants/themeColors';
import Document from '../../types/Document';
import { getJson } from '../../util/fetchWrapper';
import useAuthZero from '../../hooks/useAuthZero';
import notifyError from '../../util/notifyError';

const ListItemStyled = styled(List.Item)`
  padding: 0 !important;
`;

const DeleteButton = styled(Button)`
  color: ${themeColors.background.gray};
  &:hover, &:focus, &:active {
    color: ${themeColors.semantic.danger};
  }
`;

const DownloadButton = styled(Button)`
  color: ${themeColors.background.gray};
  &:hover, &:focus, &:active {
    color: ${themeColors.brand.primaryLight};
  }
`;

interface DocumentListItemProps {
  disabledDelete: boolean;
  doc: Document;
  onDelete: () => void;
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const DocumentListItem: React.FC<DocumentListItemProps> = ({
  doc,
  disabledDelete,
  onDelete,
  className,
  children,
  style,
}) => {
  const { getAccessToken } = useAuthZero();

  async function downloadFile() {
    try {
      const accessToken = await getAccessToken('read')();
      const { url } = await getJson(APIRoutes.document(doc.id), accessToken);
      const anchor = document.createElement('a');
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('rel', 'noreferrer noopener');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', doc.name);
      anchor.click();
    } catch (err) {
      notifyError('Unable to download document', (err as Error).message);
    }
  }

  return (
    <ListItemStyled
      style={style}
      actions={[
        <DownloadButton
          type="link"
          aria-label={`Download ${doc.name}`}
          icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
          onClick={downloadFile}
        />,
        <DeleteButton
          aria-label="Remove from document"
          type="link"
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          onClick={onDelete}
          disabled={disabledDelete}
        />,
      ]}
      className={className}
    >
      {children}
    </ListItemStyled>
  );
};

export default DocumentListItem;
