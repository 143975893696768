import JobAppNote from '../../types/JobAppNote';
import { postForJson } from '../xhr';

export default function xhrCreateJobAppNote(
  accessToken: string,
  details: Partial<JobAppNote>,
) {
  return postForJson(
    `/job-app-notes`,
    details,
    accessToken,
  );
}
