import JobApp from '../../types/JobApp';
import { postForJson } from '../xhr';

export default function xhrCreateJobApp(
  boardId: string,
  columnId: string,
  details: Partial<JobApp>,
  accessToken: string,
) {
  return postForJson(
    `/jobappboards/${boardId}/columns/${columnId}/jobapps`,
    details,
    accessToken,
  );
}
