import {
  Form,
} from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import PageTitle from '../components/JobAppBoard/common/PageTitle/PageTitle';
import SettingsAccountNameForm from '../components/JobAppBoard/SettingsAccount/SettingsAccountNameForm';
import SettingsAccountPasswordForm from '../components/JobAppBoard/SettingsAccount/SettingsAccountPasswordForm';
import { UserContext } from '../contexts/UserProvider';

const LabelStyles = styled.span`
  font-weight: 600;
`

const SettingsAccount: React.FC = () => {
  const { user } = useContext(UserContext);

  return (
    <div>
      <PageTitle>Account Settings</PageTitle>
      <Form layout="vertical">
        <Form.Item label={<LabelStyles>Email</LabelStyles>}>
          <span className="ant-form-text">{user?.email}</span>
        </Form.Item>
      </Form>
      <SettingsAccountNameForm />
      <SettingsAccountPasswordForm />
    </div>
  );
};

export default SettingsAccount;
