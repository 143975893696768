import { Button, Space } from "antd";
import { useState } from "react";
import notifyError from "../../util/notifyError";
import RichTextFormInput from "./RichTextFormInput";

interface RichEditorProps {
  initialValue?: string;
  onSave: (newContent: string) => void;
  onCancel: () => void
}

const RichEditor: React.FC<RichEditorProps> = ({
  initialValue,
  onSave,
  onCancel,
}) => {
  const [newContent, setNewContent] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState<boolean>(false);
  // const { updateJobApp } = useContext(JobAppContext);

  function discardChanges() {
    setNewContent(initialValue || undefined);
    onCancel();
  }

  async function saveChanges() {
    if (newContent === undefined || newContent === initialValue) {
      // The user didn't do anything
      discardChanges()
      return;
    }
    setSaving(true)
    const toSave = newContent === '<p><br></p>' ? '' : newContent;
    try {
      await onSave(toSave);
    } catch (err) {
      notifyError('Unable to save changes', (err as Error).message);
      discardChanges()
    } finally {
      setSaving(false)
    }
  }

  function onContentChange(editorState: string) {
    const text = editorState;
    if (!initialValue && !text) {
      setNewContent(undefined);
    } else {
      setNewContent(editorState);
    }
  }

  return (
    <div>
      <RichTextFormInput
        value={newContent || initialValue}
        onChange={onContentChange}
        withToolbar
        style={{width: '100%'}}
      />
      <Space style={{marginTop: '0.5rem'}}>
        <Button onClick={saveChanges} type="primary" disabled={saving}>Save</Button>
        <Button onClick={discardChanges} disabled={saving}>Cancel</Button>
      </Space>
    </div>
  );
}

export default RichEditor;
