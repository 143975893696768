import { deleteFor } from '../xhr';

export default async function xhrDeleteInterview(
  interviewId: string,
  accessToken: string,
) {
  await deleteFor(
    `/interviews/${interviewId}`,
    accessToken,
  );
}
