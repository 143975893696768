import { useEffect } from 'react';
import { Redirect } from '@reach/router';
import pages from '../constants/pages';
import useAuthZero from '../hooks/useAuthZero';
import Loading from '../components/JobAppBoard/Loading';

function Login() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuthZero();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        redirectUri: `${window.location.origin}${pages.BOARD}`,
        screen_hint: 'login',
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isAuthenticated) {
    return <Redirect to={pages.BOARD} noThrow />;
  }

  /**
   * Always show a loading page unless the user is authenticated.
   * The useEffect will eventually redirect them to login via Auth0.
   */
  return (
    <Loading />
  );
}

export default Login;
