import LocationAutocomplete from '../../types/LocationAutocomplete';
import { getJson } from '../xhr';

export default function xhrGetLocationsAutoComplete(
  accessToken: string,
  term: string,
): Promise<LocationAutocomplete[]> {
  const encodedTerm = encodeURIComponent(term);

  return getJson(`/locations?term=${encodedTerm}`, accessToken);
}
