import { faComments, faFileAlt, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate, useLocation } from '@reach/router';
import { Menu, Space } from 'antd';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import pages from '../../constants/pages';
import { AppOrganizationViewContext, ORGANIZATION_ID_KEY } from '../../contexts/AppOrganizationViewProvider';
import MenuSideBarViewSelector from './MenuSideBarViewSelector/MenuSideBarViewSelector';

const StyledMenu = styled(Menu)`
  background: transparent;
`;

const MenuSideBarContents: React.FC = () => {
  const { pathname } = useLocation();
  const {
    organizations,
    switchToPersonal,
    switchToOrganization
  } = useContext(AppOrganizationViewContext);

  let currentPathName = pathname

  useEffect(() => {
    const storedOrgId = localStorage.getItem(ORGANIZATION_ID_KEY)
    if (!storedOrgId) {
      switchToPersonal()
    } else if (storedOrgId && organizations && organizations.find((o) => o.id === storedOrgId)) {
      switchToOrganization(storedOrgId).catch(err => console.error(err))
    }
  }, [organizations, switchToOrganization, switchToPersonal])

  if (pathname.startsWith(pages.BOARD)) {
    currentPathName = pages.BOARD;
  }

  if (pathname.startsWith(pages.INTERVIEWS)) {
    currentPathName = pages.INTERVIEWS
  }

  return (
    <>
      <Space direction="vertical" style={{
        width: '100%',
        marginTop: '3rem',
        paddingLeft: '1rem',
        paddingRight: '1rem'
      }}>
        <MenuSideBarViewSelector />
        <StyledMenu
          mode="inline"
          selectedKeys={[currentPathName]}
          theme="dark"
          items={[{
            key: pages.BOARD,
            icon: <FontAwesomeIcon icon={faList} style={{ marginRight: '1rem' }} />,
            label: 'Boards',
            onClick: () => navigate(pages.BOARD)
          }, {
            key: pages.DOCUMENTS,
            icon: <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '1rem' }} />,
            label: 'Documents',
            onClick: () => navigate(pages.DOCUMENTS)
          }, {
            key: pages.INTERVIEWS,
            icon: <FontAwesomeIcon icon={faComments} style={{ marginRight: '1rem' }} />,
            label: 'Interviews',
            onClick: () => navigate(pages.INTERVIEWS),
         
          }]}
        >
        </StyledMenu>
      </Space>

    </>
  );
};

export default MenuSideBarContents;
