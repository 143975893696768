import React, {
  useContext, useEffect, useState,
} from 'react';
import xhrGetFeatureFlags from '../adapters/featureFlags/xhrGetFeatureFlags';
import FEATURE_FLAGS from '../constants/featureFlags';
import { UserContext } from './UserProvider';

type FeatureFlagKey = typeof FEATURE_FLAGS[keyof typeof FEATURE_FLAGS]

type FeatureFlagContextData = {
  // featureFlags: Record<FeatureFlagKeys, boolean>;
  hasFeatureEnabled: (key: FeatureFlagKey) => boolean;
  loaded: boolean;
};


const defaultFunction = () => {
  throw new Error('Not implemented');
};


export const FeatureFlagContext = React.createContext<FeatureFlagContextData>({
  loaded: false,
  hasFeatureEnabled: defaultFunction,
});

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const [featureFlagState, setFeatureFlagState] = useState<Partial<Record<FeatureFlagKey, boolean>>|null>(null);
  const { getAccessToken } = useContext(UserContext);

  useEffect(() => {
    getAccessToken()
      .then(token => xhrGetFeatureFlags(token))
      .then(res => {
        setFeatureFlagState(res)
      })
      .catch(console.error);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasFeatureEnabled: FeatureFlagContextData['hasFeatureEnabled'] = (key) => {
    return !!featureFlagState?.[key];
  };

  return (
    <FeatureFlagContext.Provider value={{
      hasFeatureEnabled,
      loaded: !!featureFlagState,
    }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};
