import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import RichTextToolbar from './RichTextToolbar';

interface RichTextFormInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  heightLimit?: string;
  withToolbar?: boolean;
  style?: React.CSSProperties;
}

interface ContainerProps {
  heightLimit?: string;
}

const Container = styled.div<ContainerProps>`
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* box-shadow: inset 0px 1px 8px -3px #ABABAB; */
  background: #fefefe;
  max-height: ${(props) => (props.heightLimit || '')};
  overflow: auto;
  transition: all .5s;
  min-height: 150px;
  
  .ql-editor {
    min-height: 150px;
    font-size: 14px;
  }
  .ql-container {
    border: none;
  }
  .ql-editor {
    /** To match ant design textinput padding */
    padding: 4px 11px;
  } 
  &:hover {
    border-color: #75a1ff;
  }
  &:focus-within {
    border-color: #75a1ff;
  }
  padding: 0;
`;

const RichTextFormInput: React.FC<RichTextFormInputProps> = ({
  // eslint-disable-next-line react/prop-types
  value, onChange, onBlur, heightLimit, style,
}) => {
  const defaultEditorState = '';

  function onEditorChange(newEditorState: string) {
    if (onChange) {
      onChange(newEditorState);
    }
  }

  function onEditorBlur() {
    if (onBlur) {
      onBlur();
    }
  }

  return (
    <>
      <RichTextToolbar />
      <Container heightLimit={heightLimit} style={style} className="text-editor">
        <ReactQuill
          value={value || defaultEditorState}
          onChange={onEditorChange}
          onBlur={onEditorBlur}
          modules={{
            toolbar: {
              container: '#toolbar',
            },
          }}
          formats={[
            // Only allow these formats (such as when thy copy+paste)
            // Not every format has a button in the toolbar however.
            'bold',
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike',
            'list', 'bullet', 'link',
          ]}
        />
      </Container>
    </>
  );
};

export default RichTextFormInput;
