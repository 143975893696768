import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import AuthZero from '../constants/AuthZero';
import { getJson } from '../util/fetchWrapper';

function useAuthZero() {
  const auth0 = useAuth0();
  const { getAccessTokenSilently, user } = auth0;
  const getAccessToken = useCallback((scope: string = 'read') => {
    function getAccessTokenWithConstants() {
      return getAccessTokenSilently({
        audience: AuthZero.audience,
        scope: AuthZero.scope[scope],
      });
    }
    return getAccessTokenWithConstants;
  }, [getAccessTokenSilently]);

  const getMetadata = useCallback(async () => {
    if (!user) {
      throw new Error('No user is defined while getting metadata');
    };
    
    const accessToken = await getAccessToken()();
    const userDetailsByIdUrl = `${AuthZero.audience}users/${user.sub}`;
    const metadataResponse = await getJson(userDetailsByIdUrl, accessToken);
    // eslint-disable-next-line
    const { user_metadata, app_metadata } = metadataResponse;
    return {
      userMetadata: user_metadata || {},
      appMetadata: app_metadata || {},
    };
  }, [getAccessToken, user]);

  return {
    ...auth0,
    getAccessToken,
    getMetadata,
  };
}

export default useAuthZero;
