import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import notifyError from '../../../util/notifyError';
import JobApp from '../../../types/JobApp';
import DatePicker from '../../DatePicker';
import { DATE_FORMAT } from '../../../constants/dateTimeFormats';
import { JobAppContext } from '../../../contexts/JobAppProvider';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

interface JobAppAppliedDatePickerProps {
  jobApp: JobApp;
  style?: React.CSSProperties;
}

const JobAppAppliedDatePicker: React.FC<JobAppAppliedDatePickerProps> = ({ jobApp, style }) => {
  const { updateJobApp } = useContext(JobAppContext);

  async function edit(appliedAt: string) {
    try {
      const toPost = {
        appliedAt,
      };
      await updateJobApp(jobApp.id, toPost);
    } catch (err) {
      notifyError('Unable to edit data', (err as Error).message);
    }
  }

  return (
    <DatePicker
      style={style}
      allowClear={false}
      defaultValue={dayjs(jobApp.appliedAt)}
      format={DATE_FORMAT}
      onChange={(date) => {
        if (!date) {
          /**
           * DatePicker typically allows you to clear dates, but we disallow that here with
           * allowClear={false}.
           */
          return;
        }
        edit(date.utc().toISOString());
      }}
      suffixIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
    />
  );
};

export default JobAppAppliedDatePicker;
