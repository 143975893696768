import React, { useContext, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import JobAppCard from '../Card';
import DroppableTypes from '../../../constants/DroppableTypes';
import ColumnSettingsDropdown from '../ColumnSettingsDropdown';
import EditableInput from '../../EditableText';
import notifyError from '../../../util/notifyError';
import JobAppColumnType from '../../../types/JobAppColumn';
import JobApp from '../../../types/JobApp';
import AddJobAppModal from '../AddJobAppModal';
import EditJobAppBoardColumnModal from '../EditJobAppBoardColumnModal';
import { JobAppColumnContext } from '../../../contexts/JobAppColumnProvider';
import { ColumnAddJobButtonStyles, ColumnBodyStyles, ColumnDroppableAreaStyles, ColumnHeaderStyles, ColumnStyles } from './Column.styles';
import { Typography } from 'antd'

interface JobAppColumnProps {
  index: number;
  column: JobAppColumnType;
  jobApps: JobApp[];
  disableDrag: boolean;
}

const Column: React.FC<JobAppColumnProps> = ({
  index, column, jobApps, disableDrag,
}) => {
  const { name } = column;
  const [addingJobApp, setAddingJobApp] = useState(false);
  const [editingColumn, setEditingColumn] = useState(false);
  const { updateJobAppColumn } = useContext(JobAppColumnContext);

  async function onColumnNameChange(newColumnName: string) {
    if (newColumnName.length === 0) {
      return;
    }
    try {
      await updateJobAppColumn(column.boardId, column.id, {
        name: newColumnName,
      });
    } catch (err) {
      notifyError('Unable to change column name', (err as Error).message);
    }
  }

  return (
    <>
      <Draggable
        index={index}
        draggableId={column.id}
        isDragDisabled={!!disableDrag}
      >
        {(columnProvided) => (
          <ColumnStyles
            ref={columnProvided.innerRef}
            {...columnProvided.draggableProps}

          >
            <EditJobAppBoardColumnModal
              column={column}
              visible={editingColumn}
              onClose={() => setEditingColumn(false)}
            />
            <AddJobAppModal
              column={column}
              visible={addingJobApp}
              onClose={() => setAddingJobApp(false)}
            />
            <ColumnHeaderStyles
              {...columnProvided.dragHandleProps}
            >
              <div>
                <EditableInput
                  value={name}
                  onChange={onColumnNameChange}
                  fontSize="1rem"
                  fontWeight={600}
                  textLabel={`Click to edit column name ${column.name}`}
                  inputLabel="New column name"
                  textProps={{
                    style: {
                      marginLeft: '-5px'
                    }
                  }}
                />
                <Typography.Text type="secondary">
                  {`${jobApps.length} job${jobApps.length === 1 ? '' : 's'}`}
                </Typography.Text>
              </div>
              <ColumnSettingsDropdown
                onClickEdit={() => setEditingColumn(true)}
                column={column}
                jobApps={jobApps}
              />
            </ColumnHeaderStyles>
            <ColumnAddJobButtonStyles
              onClick={() => setAddingJobApp(true)}
              shape="round"
            >
              <FontAwesomeIcon icon={faPlus} />
            </ColumnAddJobButtonStyles>
            <ColumnBodyStyles>

              <Droppable droppableId={column.id} type={DroppableTypes.JOB}>
                {(provided, snapshot) => (
                  <ColumnDroppableAreaStyles
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    {jobApps.map((app, appIndex) => (
                      <JobAppCard
                        key={app.id}
                        column={column}
                        jobApp={app}
                        index={appIndex}
                        disableDrag={disableDrag}
                      />
                    ))}
                    {provided.placeholder}
                  </ColumnDroppableAreaStyles>
                )}
              </Droppable>
            </ColumnBodyStyles>
          </ColumnStyles>
        )}
      </Draggable>
    </>
  );
};

export default Column;
