import React from 'react';
import { Modal, ModalProps } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StyledModal = styled(Modal)`
`;

interface ThemedModalProps extends ModalProps {}

const ThemedModal: React.FC<ThemedModalProps> = (props) => {
  const { children } = props;
  return (
    <StyledModal
      destroyOnClose
      closeIcon={(<FontAwesomeIcon icon={faTimes} />)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </StyledModal>
  );
};

export default ThemedModal;
