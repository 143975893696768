import { deleteFor } from '../xhr';

export default function xhrDetachJobAppDocument(
  boardId: string,
  columnId: string,
  jobAppId: string,
  documentId: string,
  accessToken: string,
) {
  return deleteFor(
    `/jobappboards/${boardId}/columns/${columnId}/jobapps/${jobAppId}/documents/${documentId}`,
    accessToken,
  );
}
