import JobAppNote from '../../types/JobAppNote';
import { patchForJson } from '../xhr';

export default function xhrUpdateJobAppNote(
  accessToken: string,
  id: string,
  details: Partial<JobAppNote>,
) {
  return patchForJson(
    `/job-app-notes/${id}`,
    details,
    accessToken,
  );
}
