import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import pages from '../../../constants/pages';
import { JobAppImportProvider } from '../../../contexts/JobAppImportProvider';
import BulkJobApp from '../../../types/BulkJobApp';
import MapFields from './steps/MapFields';
import UploadCsv from './steps/UploadCsv';
import UploadingCsvJobs from './steps/UploadingCsvJobs';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const WIZARD_STEPS = {
  UPLOAD_CSV: 0,
  MAP_FIELDS: 1,
  UPLOADING_JOBAPPS: 2,
};

const stepHeaders = new Map([
  [WIZARD_STEPS.UPLOAD_CSV, 'Upload CSV'],
  [WIZARD_STEPS.MAP_FIELDS, 'Organize your job apps'],
  [WIZARD_STEPS.UPLOADING_JOBAPPS, 'Importing applicatiions...'],
]);

const BoardImport: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(WIZARD_STEPS.UPLOAD_CSV);
  const [uploadedCsv, setUploadedCsv] = useState<string[][]|undefined>();
  // An array dictating which columns dictate to which properties.
  // If an index is undefined, then the user hasn't selected a property for it yet
  const [
    selectedProperties,
    setSelectedProperties,
  ] = useState<Array<keyof BulkJobApp|undefined>>([]);
  const [csvMapping, setCsvMapping] = useState<BulkJobApp[]|undefined>();

  // When CSV has been uploaded, move to the next step
  useEffect(() => {
    if (!uploadedCsv) {
      setCurrentStep(WIZARD_STEPS.UPLOAD_CSV);
    } else if (!csvMapping) {
      setCurrentStep(WIZARD_STEPS.MAP_FIELDS);
    } else {
      setCurrentStep(WIZARD_STEPS.UPLOADING_JOBAPPS);
    }
  }, [uploadedCsv, csvMapping, currentStep]);

  function goBackToBoards() {
    navigate(pages.BOARD);
  }

  const tabTitleText = stepHeaders.has(currentStep)
    ? `Import Board (${stepHeaders.get(currentStep)})`
    : '';

  // The text that shows up on the browser tab
  const tabTitle = (
    <Helmet>
      <title>{`Import Board: ${tabTitleText}`}</title>
    </Helmet>
  );

  return (
    <JobAppImportProvider>
      <Wrapper>
        {tabTitle}
        {!uploadedCsv && (
          <UploadCsv
            onFileUploaded={setUploadedCsv}
            onCancel={goBackToBoards}
          />
        )}
        {uploadedCsv && !csvMapping && (
          <MapFields
            csvContents={uploadedCsv}
            onCsvUpdated={setUploadedCsv}
            selectedProperties={selectedProperties}
            onSelectedPropertiesUpdate={setSelectedProperties}
            onMappingSet={setCsvMapping}
            // Clear the uploaded csv and the properties
            onGoBack={() => {
              setSelectedProperties([]);
              setUploadedCsv(undefined);
            }}
          />
        )}
        {uploadedCsv && csvMapping && (
          <UploadingCsvJobs
            onGoBack={() => setCsvMapping(undefined)}
            onUploaded={goBackToBoards}
            csvMapping={csvMapping}
          />
        )}
      </Wrapper>
    </JobAppImportProvider>
  );
};

export default BoardImport;
